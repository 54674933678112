import { gql } from '@apollo/client';

export const PUBLISHED_GAMES = gql`
  query AdminGames($filter: AdminGamesFilter!, $sort: AdminGamesSort) {
    adminGames(filter: $filter, sort: $sort) {
      data {
        id
        name
        link
        category {
          id
          key
          name
        }
        version
        description
        platform
        orientation
        mode
        type
        status
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const PUBLISHED_SINGLE_GAME = gql`
  query AdminGame($where: GameWhereUniqueInput) {
    adminGame(where: $where) {
      id
      name
      link
      category {
        id
        key
        name
      }
      version
      description
      platform
      orientation
      mode
      type
      createdBy {
        name
        email
        id
      }
      status
      createdAt
      updatedAt
      config {
        id
        gameTime
        findOpponentsTime
        live
        updatedAt
        isOpponentsRequired
        winingAmountMargin
        playFree
        coinOption
        createdAt
      }
      asset {
        id
        youtubeUrl
        icon {
          key
          url
        }
        logotype {
          url
          key
        }
        backgroundImage {
          url
          key
        }
        screenshots {
          url
          key
        }
      }
      rating
      highestScore
    }
  }
`;

export const GAME_GENRE = gql`
  query GameCategories(
    $filter: GameCategoriesFilter!
    $sort: GameCategoriesSort
  ) {
    gameCategories(filter: $filter, sort: $sort) {
      data {
        key
        name
        id
      }
      count
    }
  }
`;

export const IMAGE_SIGN_URL = gql`
  query GetGameImageSignUrl($data: getGameSignUrlData!) {
    getGameImageSignUrl(data: $data) {
      key
      signedUrl
    }
  }
`;
