import { useLazyQuery } from '@apollo/client';
import { Badge, Card, Col, Divider, Row, Select, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  GameDataLogo,
  RevenueIcon,
  WinningAmountIcon,
  WithdrawalsIcon,
} from '../../assets/svg';
import { userGameStatsFilter } from '../../common/constants';
import CommonCharts from '../../components/CommonCharts';
import LoaderComponent from '../../components/LoaderComponent';
import {
  SINGLE_USER_STATISTICS,
  USER_GAME_STATISTICS,
} from './graphql/Queries';

const { Title } = Typography;
const options = {
  legend: 'none',
  pieSliceText: 'none',
  chartArea: {
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
  },
  slices: {
    0: { color: '#7878DD' },
    1: { color: '#29284B' },
  },
};

const UserStatistics = () => {
  const { id } = useParams();
  const [personData, setPersonData] = useState();
  const [userGameStatsData, setUserGameStatsData] = useState();
  const [gameStatsFilter, setGameStatsFilter] = useState('ALL_TIME');
  const [getPersonData, { loading: FetchPerson }] = useLazyQuery(
    SINGLE_USER_STATISTICS,
    {
      fetchPolicy: 'network-only',
      onCompleted({ adminStatistics: personStatsData }) {
        setPersonData(personStatsData);
      },
    },
  );

  const [getUserGameStats, { loading: userGameStatsLoading }] = useLazyQuery(
    USER_GAME_STATISTICS,
    {
      fetchPolicy: 'network-only',
      onCompleted({ userGameStatistics: userGameData }) {
        setUserGameStatsData(userGameData);
      },
    },
  );

  useEffect(() => {
    if (id) {
      getPersonData({
        variables: { where: { userId: id } },
      });
    }
    getUserGameStats({
      variables: {
        filter: {
          date: gameStatsFilter,
        },
        where: {
          userId: id,
        },
      },
    });
  }, [id]);

  const totalGamePlayed = userGameStatsData?.played;
  const gamesWin = userGameStatsData?.won;
  const gamesLose = totalGamePlayed - gamesWin;

  const userGamePlayedStats = [
    ['Task', 'Player Data'],
    ['Win', gamesWin],
    ['Lose', gamesLose],
  ];

  const handleUserGameStatsFilter = (response) => {
    setGameStatsFilter(response);
    getUserGameStats({
      variables: {
        filter: {
          date: response,
        },
        where: {
          userId: id,
        },
      },
    });
  };
  return (
    <div>
      <Title level={5} className="mt-0 mb-24">
        Statistics
      </Title>
      <LoaderComponent spinning={FetchPerson}>
        <div className="statastics-wrap">
          <div className="statastics-card">
            <RevenueIcon />
            <Title level={2}>{personData?.balance?.balance}</Title>
            <p>Balance</p>
          </div>
          <div className="statastics-card">
            <WinningAmountIcon />
            <Title level={2}>{personData?.balance?.depositAmount}</Title>
            <p>Deposit Amount</p>
          </div>
          <div className="statastics-card">
            <WithdrawalsIcon />
            <Title level={2}>{personData?.balance?.winingAmount}</Title>
            <p>Winning Amount</p>
          </div>
        </div>
        <Row gutter={[16, 16]}>
          <Col xs={24} md={24} xl={24}>
            <Card
              className="mt-24"
              title="Games Data"
              extra={
                <Select
                  options={userGameStatsFilter}
                  onChange={handleUserGameStatsFilter}
                  value={gameStatsFilter}
                />
              }
            >
              <LoaderComponent spinning={userGameStatsLoading}>
                {totalGamePlayed > 0 ? (
                  <Row justify="end">
                    <Col xs={24} sm={16} md={12} xl={12}>
                      <Badge
                        color="#4caf50"
                        text={
                          <>
                            Total numbers of games played:
                            <strong>{totalGamePlayed}</strong>
                          </>
                        }
                      />
                      <Divider />
                      <Badge
                        color="#7878DD"
                        text={
                          <>
                            Total numbers of games won:
                            <strong>{gamesWin}</strong>
                          </>
                        }
                      />
                      <Divider />
                      <Badge
                        color="#1d2538"
                        text={
                          <>
                            Total numbers of games Lose:
                            <strong>{gamesLose}</strong>
                          </>
                        }
                      />
                    </Col>

                    <Col xs={24} sm={16} md={12} xl={12}>
                      <CommonCharts
                        chartType="PieChart"
                        data={userGamePlayedStats}
                        options={options}
                        width="100%"
                        height="250px"
                      />
                    </Col>
                  </Row>
                ) : (
                  <div className="no-game-data">
                    <GameDataLogo />
                    No data found
                  </div>
                )}
              </LoaderComponent>
            </Card>
          </Col>
        </Row>
      </LoaderComponent>
    </div>
  );
};

export default UserStatistics;
