import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import User from './User';

import UserEdit from './EditUser';

function UserWrapper() {
  return (
    <Switch>
      <Route exact path={ROUTES?.USERS} component={User} />
      <Route exact path={`${ROUTES?.USERS}/edit/:id`} component={UserEdit} />
    </Switch>
  );
}

export default UserWrapper;
