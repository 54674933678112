import { gql } from '@apollo/client';

export const PUBLISHED_EDIT_BASICDETAIL = gql`
  mutation UpdateGame($where: GameWhereUniqueInput!, $data: UpdateGameInput!) {
    updateGame(where: $where, data: $data) {
      message
      data {
        version
        updatedAt
        type
        status
        platform
        orientation
        name
        mode
        category {
          key
          id
          name
        }
      }
    }
  }
`;

export const DELETE_GAME = gql`
  mutation DeleteGame($where: GameWhereUniqueInput!) {
    deleteGame(where: $where) {
      message
    }
  }
`;

export const CONFIGURATION_GAME = gql`
  mutation UpsertGameConfiguration(
    $data: GameConfigurationInput!
    $where: GameWhereUniqueInput
  ) {
    upsertGameConfiguration(data: $data, where: $where) {
      data {
        id
        gameTime
        findOpponentsTime
        live
        isOpponentsRequired
        winingAmountMargin
        playFree
        coinOption
        createdAt
        updatedAt
      }
      message
    }
  }
`;

export const ASSET_GAME = gql`
  mutation UpsertGameAsset(
    $where: GameWhereUniqueInput!
    $data: GameAssetInput!
  ) {
    upsertGameAsset(where: $where, data: $data) {
      data {
        id
        youtubeUrl
        icon {
          key
          url
        }
        logotype {
          key
          url
        }
        backgroundImage {
          key
          url
        }
        screenshots {
          key
          url
        }
      }
      message
    }
  }
`;

export const UPDATE_STATUS = gql`
  mutation UpdateGameStatus(
    $where: GameWhereUniqueInput
    $data: UpdateGameStatusInput
  ) {
    updateGameStatus(where: $where, data: $data) {
      data {
        status
        id
        name
      }
      message
    }
  }
`;
