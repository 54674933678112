import { gql } from '@apollo/client';

const USER_STATISTICS = gql`
  query UserStatistics($filter: DashBoardDateFilter!) {
    userStatistics(filter: $filter) {
      referralsCount
      usersCount {
        month
        count
      }
    }
  }
`;
export default USER_STATISTICS;

export const GAME_NET_REVENUES = gql`
  query NetRevenue(
    $where: GameWhereUniqueInput!
    $filter: DashBoardDateFilter!
  ) {
    netRevenue(where: $where, filter: $filter) {
      netRevenue {
        amount
        month
      }
    }
  }
`;

export const GAME_COUNT = gql`
  query AdminStatistics(
    $where: GameWhereUniqueInput!
    $filter: DashBoardDateFilter!
  ) {
    gamePlayedCount(where: $where, filter: $filter) {
      count
    }
  }
`;
