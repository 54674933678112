import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import Configuration from './Configuration';

function ConfigurationWrapper() {
  return (
    <Switch>
      <Route exact path={ROUTES?.CONFIGURATION} component={Configuration} />
    </Switch>
  );
}

export default ConfigurationWrapper;
