import { useLazyQuery } from '@apollo/client';
import { Card, Col, DatePicker, Row, Select, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  GameIcon,
  RevenueIcon,
  UserGroupIcon,
  UserGroupReferralIcon,
} from '../../assets/svg';
import { currencyFormate } from '../../common/utils';
import LoaderComponent from '../../components/LoaderComponent';
import NumberCounter from '../../components/NumberCounter';
import { ADMIN_GAMES } from '../game/drafts/graphql/Query';
import USER_STATISTICS, {
  GAME_COUNT,
  GAME_NET_REVENUES,
} from './graphql/Queries';

const { Title } = Typography;
const { RangePicker } = DatePicker;
const initialGameFilter = {
  filter: {
    status: 'PUBLISHED',
    limit: null,
  },
  sort: {
    sortBy: 'DESC',
  },
};

function DashboardStatistics() {
  const [adminGamesData, setAdminGamesData] = useState([]);
  const [gameNetRevenue, setGameNetRevenue] = useState();
  const [netRevenueGameId, setNetRevenueGameId] = useState('');
  const [netRevenueDateFrom, setNetRevenueDateFrom] = useState(null);
  const [netRevenueDateTo, setNetRevenueDateTo] = useState(null);
  const [gamePlayedDateFrom, setGamePlayedDateFrom] = useState(null);
  const [gamePlayedDateTo, setGamePlayedDateTo] = useState(null);
  const [gamePlayedCount, setGamePlayedCount] = useState(null);
  const [gameCountId, setGameCountId] = useState('');
  const [userStatsData, setUserStatsData] = useState(null);
  const [userStatsDateFrom, setUserStatsDateFrom] = useState(null);
  const [userStatsDateTo, setUserStatsDateTo] = useState(null);

  const [adminGames, { loading: adminGamesLoading }] = useLazyQuery(
    ADMIN_GAMES,
    {
      fetchPolicy: 'network-only',
      onCompleted({ adminGames: gameData }) {
        setAdminGamesData(gameData?.data);
      },
    },
  );

  const [getGameNetRevenues, { loading: netRevenueLoading }] = useLazyQuery(
    GAME_NET_REVENUES,
    {
      fetchPolicy: 'network-only',
      onCompleted({ netRevenue: gameNetRevenueData }) {
        setGameNetRevenue(gameNetRevenueData?.netRevenue);
      },
      onError() {},
    },
  );

  const [
    getGamePlayedCount,
    { loading: gamePlayedCountLoading },
  ] = useLazyQuery(GAME_COUNT, {
    fetchPolicy: 'network-only',
    onCompleted({ gamePlayedCount: gameCount }) {
      setGamePlayedCount(gameCount?.count);
    },
  });

  const [getUserStatistics, { loading: userStatsLoading }] = useLazyQuery(
    USER_STATISTICS,
    {
      fetchPolicy: 'network-only',
      onCompleted({ userStatistics: statsData }) {
        setUserStatsData(statsData);
      },
      onError() {},
    },
  );

  useEffect(() => {
    adminGames({
      variables: initialGameFilter,
    });
  }, []);
  useEffect(() => {
    getGameNetRevenues({
      variables: {
        where: {
          id: netRevenueGameId,
        },
        filter: {
          date: {
            from: netRevenueDateFrom,
            to: netRevenueDateTo,
          },
        },
      },
    });
  }, [netRevenueDateFrom, netRevenueDateTo, netRevenueGameId]);
  useEffect(() => {
    getGamePlayedCount({
      variables: {
        where: {
          id: gameCountId,
        },
        filter: {
          date: {
            from: gamePlayedDateFrom,
            to: gamePlayedDateTo,
          },
        },
      },
    });
  }, [gamePlayedDateFrom, gamePlayedDateTo, gameCountId]);
  useEffect(() => {
    getUserStatistics({
      variables: {
        filter: {
          date: {
            from: userStatsDateFrom,
            to: userStatsDateTo,
          },
        },
      },
    });
  }, [userStatsDateTo, netRevenueGameId]);

  const totalRevenueAmount = gameNetRevenue?.reduce(
    (total, currentItem) => total + currentItem?.amount,
    0,
  );
  const totalUserCount =
    userStatsData?.usersCount?.reduce(
      (total, currentItem) => total + currentItem?.count,
      0,
    ) || 0;
  const netRevenue = currencyFormate?.format(totalRevenueAmount || 0);

  const handleGameFilter = (selectedGameId) => {
    setNetRevenueGameId(selectedGameId);
  };

  const handleGamePlayedCountFilter = (selectedId) => {
    setGameCountId(selectedId);
  };

  const handleNetRevenueDate = (dateRange) => {
    setNetRevenueDateFrom(dateRange?.[0] || null);
    setNetRevenueDateTo(dateRange?.[1] || null);
  };
  const handleGamePlayedDate = (dateRange) => {
    setGamePlayedDateFrom(dateRange?.[0] || null);
    setGamePlayedDateTo(dateRange?.[1] || null);
  };
  const handleUserStatsDate = (dateRange) => {
    setUserStatsDateFrom(dateRange?.[0] || null);
    setUserStatsDateTo(dateRange?.[1] || null);
  };

  return (
    <div className="mb-24 d-statistics">
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={24} lg={12} xl={8}>
          <LoaderComponent spinning={netRevenueLoading || adminGamesLoading}>
            <Card
              size="small"
              className="text-center"
              actions={[
                <RangePicker
                  key="dateRange"
                  bordered={false}
                  onChange={handleNetRevenueDate}
                />,
                <Select
                  key="selectGame"
                  bordered={false}
                  options={[
                    { label: 'All Games', value: '' },
                    ...adminGamesData?.map(({ id, name }) => ({
                      label: name,
                      value: id,
                    })),
                  ]}
                  onChange={handleGameFilter}
                  defaultValue="All Games"
                />,
              ]}
            >
              <div className="d-flex" />
              <div className="st-icon">
                <RevenueIcon />
              </div>
              <Title level={3} className="mt-0">
                {netRevenue}
              </Title>
              <Title level={5} className="mt-0">
                Net Revenue
              </Title>
            </Card>
          </LoaderComponent>
        </Col>
        <Col xs={24} sm={24} lg={12} xl={8}>
          <LoaderComponent
            spinning={gamePlayedCountLoading || adminGamesLoading}
          >
            <Card
              size="small"
              className="text-center"
              actions={[
                <RangePicker
                  bordered={false}
                  key="dateRange"
                  onChange={handleGamePlayedDate}
                />,
                <Select
                  key="selectGame"
                  bordered={false}
                  options={[
                    { label: 'All Game', value: '' },
                    ...adminGamesData?.map(({ id, name }) => ({
                      label: name,
                      value: id,
                    })),
                  ]}
                  onChange={handleGamePlayedCountFilter}
                  defaultValue="All Games"
                />,
              ]}
            >
              <div className="d-flex gap-4" />
              <div className="st-icon">
                <GameIcon />
              </div>
              <Title className="mt-0" level={3}>
                <NumberCounter value={gamePlayedCount} duration={1} />
              </Title>
              <Title level={5} className="mt-0">
                Games Played
              </Title>
            </Card>
          </LoaderComponent>
        </Col>
        <Col xs={24} sm={24} lg={24} xl={8}>
          <LoaderComponent spinning={userStatsLoading}>
            <Card
              size="small"
              className="text-center"
              actions={[
                <RangePicker
                  bordered={false}
                  key="rangForUser"
                  onChange={handleUserStatsDate}
                />,
              ]}
            >
              <div className="d-flex">
                <div className="flex-1">
                  <div className="st-icon">
                    <UserGroupIcon />
                  </div>
                  <Title className="mt-0" level={3}>
                    <NumberCounter value={totalUserCount} duration={1} />
                  </Title>
                  <Title level={5} className="mt-0">
                    Users
                  </Title>
                </div>
                <div className="flex-1">
                  <div className="st-icon">
                    <UserGroupReferralIcon />
                  </div>
                  <Title className="mt-0" level={3}>
                    <NumberCounter
                      value={userStatsData?.referralsCount || 0}
                      duration={1}
                    />
                  </Title>
                  <Title level={5} className="mt-0">
                    Referral
                  </Title>
                </div>
              </div>
            </Card>
          </LoaderComponent>
        </Col>
      </Row>
    </div>
  );
}

export default DashboardStatistics;
