import { gql } from '@apollo/client';

export const USER_LIST = gql`
  query AdminUsers($filter: AdminUsersFilter, $sort: AdminUsersSort) {
    adminUsers(filter: $filter, sort: $sort) {
      count
      users {
        id
        name
        email
        winingAmount
        level
        profileImage {
          key
          url
        }
        createdAt
        depositAmount
        friendsCount
        gamePlayed
        gameWon
        isActive
        isOnboardingCompleted
        lastLogin
        location
        referralCode
        updatedAt
      }
    }
  }
`;

export const SINGLE_USER = gql`
  query AdminUser($where: UserWhereUniqueInput) {
    adminUser(where: $where) {
      id
      name
      email
      referralCode
      profileImage {
        key
        url
      }
      depositAmount
      winingAmount
      level
      location
      lastLogin
      isOnboardingCompleted
      createdAt
      updatedAt
      isActive
    }
  }
`;

export const SINGLE_USER_STATISTICS = gql`
  query AdminStatistics($where: AdminUserWhereUniqueInput) {
    adminStatistics(where: $where) {
      balance {
        balance
        depositAmount
        winingAmount
      }
    }
  }
`;

export const USER_GAME_STATISTICS = gql`
  query UserGameStatistics(
    $filter: UserGameStatisticsFilter!
    $where: AdminUserWhereUniqueInput
  ) {
    userGameStatistics(filter: $filter, where: $where) {
      played
      won
    }
  }
`;

export const SINGLE_USER_TRANSACTION = gql`
  query AdminTransactions(
    $where: AdminUserWhereUniqueInput
    $filter: AdminTransactionsFilter
    $sort: AdminTransactionsSort
  ) {
    adminTransactions(where: $where, filter: $filter, sort: $sort) {
      data {
        id
        amount
        transactionType
        createdAt
        status
      }
      count
    }
  }
`;

export const USER_IMAGE_SIGN_URL = gql`
  query GetAdminProfileSignUrl($data: getAdminProfileSignUrlData!) {
    getAdminProfileSignUrl(data: $data) {
      signedUrl
      key
    }
  }
`;
