import { RightOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const CommonBreadcrumb = ({ crumbs }) => {
  const { pathname } = useLocation();
  const pathnames = pathname?.split('/')?.filter((item) => item);
  const capitalize = (s) => s?.charAt(0)?.toUpperCase() + s?.slice(1);
  return (
    <Breadcrumb separator={<RightOutlined />}>
      <Breadcrumb.Item>{crumbs?.name}</Breadcrumb.Item>

      {crumbs?.map((crumb, index) => {
        const routeTo = `/${pathnames?.slice(0, index + 1)?.join('/')}`;
        const isLast = index === crumbs?.length - 1;
        return isLast ? (
          <Breadcrumb.Item key="first">
            {capitalize(crumb?.name)}
          </Breadcrumb.Item>
        ) : (
          <Breadcrumb.Item key="last">
            <Link to={`${routeTo}`}>{capitalize(crumb?.name)}</Link>
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
};

export default CommonBreadcrumb;
