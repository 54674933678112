import { useMutation } from '@apollo/client';
import { Button, Col, Form, InputNumber, Row } from 'antd';
import React, { useState } from 'react';
import { formValidatorRules } from '../../common/utils';
import '../../styles/main.less';
import UPDATE_CONFIG from './graphql/Mutation';

const { required } = formValidatorRules;
const ConfigForm = ({ name, keyField, value = null, configMutation }) => {
  const [fieldValue, setFieldValue] = useState(false);
  const [updateConfiguration, { loading: updateConfig }] = useMutation(
    UPDATE_CONFIG,
    {
      onCompleted: () => {
        configMutation({
          variables: {
            sort: {
              sortBy: 'ASC',
              sortOn: 'key',
            },
          },
        });
        setFieldValue(false);
      },
    },
  );
  const handleSubmit = (values) => {
    if (values?.value?.toString() || values?.target?.value !== value) {
      updateConfiguration({
        variables: {
          data: {
            value: values?.value?.toString() || values?.target?.value,
          },
          where: {
            key: keyField,
          },
        },
      });
    }
  };
  const handleInputState = (checkInputValue) => {
    setFieldValue(checkInputValue?.toString() !== value ?? false);
  };
  return (
    <>
      <Form
        initialValues={{
          value,
        }}
        onFinish={handleSubmit}
        labelCol={{
          span: 18,
        }}
        labelAlign="left"
        labelWrap
        disabled={updateConfig}
      >
        <Row gutter={8}>
          <Col xs={24} sm={20} md={20} lg={20} xl={16}>
            <Form.Item
              name="value"
              label={name}
              rules={[{ ...required, message: `Please Enter Value!` }]}
            >
              <InputNumber
                min={0}
                type="number"
                className="full-width"
                placeholder={`Please enter ${name}`}
                onChange={handleInputState}
                onPressEnter={handleSubmit}
              />
            </Form.Item>
          </Col>
          <Col xs={2} sm={2} md={2} lg={4} xl={8}>
            <Form.Item>
              <Button
                htmlType="submit"
                type="primary"
                disabled={!fieldValue || updateConfig}
                loading={updateConfig}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default ConfigForm;
