import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import GameLeaderboard from './GameLeaderboard';
import Leaderboard from './Leaderboard';

function LeaderboardWrpper() {
  return (
    <Switch>
      <Route exact path={ROUTES?.LEADERBOARD} component={Leaderboard} />
      <Route
        exact
        path={`${ROUTES?.LEADERBOARD}/:id`}
        component={GameLeaderboard}
      />
    </Switch>
  );
}

export default LeaderboardWrpper;
