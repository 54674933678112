import { gql } from '@apollo/client';

export const GAME_CATEGORY = gql`
  query GameCategories(
    $filter: GameCategoriesFilter!
    $sort: GameCategoriesSort
  ) {
    gameCategories(filter: $filter, sort: $sort) {
      count
      data {
        id
        key
        name
        logo
        createdAt
        updatedAt
      }
    }
  }
`;
export const IMAGE_SIGN_URL = gql`
  query GetGameImageSignUrl($data: getGameSignUrlData!) {
    getGameImageSignUrl(data: $data) {
      key
      signedUrl
    }
  }
`;

export const DRAFT_LISTING = gql`
  query AdminGames($filter: GamesFilter!) {
    adminGames(filter: $filter) {
      count
      data {
        name
        updatedAt
        platform
        mode
        status
        type
      }
    }
  }
`;

export const ADMIN_GAMES = gql`
  query AdminGames($filter: AdminGamesFilter!, $sort: AdminGamesSort) {
    adminGames(filter: $filter, sort: $sort) {
      count
      data {
        id
        name
        updatedAt
        platform
        mode
        status
        type
        createdAt
        draftStatus
        rating
        totalMatchPlayed
        netRevenue
      }
    }
  }
`;

export const ADMIN_SINGLE_GAME = gql`
  query AdminGame($where: GameWhereUniqueInput) {
    adminGame(where: $where) {
      id
      name
      version
      type
      platform
      orientation
      link
      mode
      description
      category {
        id
      }

      asset {
        backgroundImage {
          key
          url
        }
        icon {
          key
          url
        }
        logotype {
          key
          url
        }
        screenshots {
          key
          url
        }
        youtubeUrl
      }

      config {
        coinOption
        findOpponentsTime
        gameTime
        isOpponentsRequired
        live
        playFree
        winingAmountMargin
      }
    }
  }
`;

export const ADMIN_LEADERBOARD = gql`
  query AdminLeaderBoard(
    $where: LeaderboardUniqueInput!
    $filter: LeaderboardFilter!
  ) {
    adminLeaderBoard(where: $where, filter: $filter) {
      data {
        createdAt
        highestScore
        id
        updatedAt
        userId
        user {
          id
          email
          isActive
          name
          lastLogin
        }
      }
      count
    }
  }
`;

export const GAME_STATISTICS = gql`
  query AdminGameStatistic($where: GameWhereUniqueInput) {
    adminGameStatistic(where: $where) {
      averageRating
      highestScore
      uniquePlayer
      netRevenue
      totalRevenue
    }
  }
`;
