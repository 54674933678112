import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import Drafts from './Drafts';
import UploadForms from './UploadForms';

function DraftWrapper() {
  return (
    <Switch>
      <Route exact path={ROUTES?.DRAFTS} component={Drafts} />
      <Route
        exact
        path={`${ROUTES?.DRAFTS}/edit/:id`}
        component={UploadForms}
      />
      <Route
        exact
        path={`${ROUTES?.DRAFTS}${ROUTES?.UPLOAD}`}
        component={UploadForms}
      />
      <Route
        exact
        path={`${ROUTES?.DRAFTS}${ROUTES?.UPLOAD}/:id`}
        component={UploadForms}
      />
    </Switch>
  );
}

export default DraftWrapper;
