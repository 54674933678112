import React from 'react';
import Chart from 'react-google-charts';

function CommonCharts(props) {
  const { chartType, data, children, ...rest } = props;
  return (
    <div>
      <Chart chartType={chartType} data={data} {...rest} />
    </div>
  );
}

export default CommonCharts;
