import React, { useEffect, useState } from 'react';

function NumberCounter({ value, duration }) {
  const [count, setCount] = useState(value);
  const increment = value / (duration * 1000); // Calculate increment per millisecond

  useEffect(() => {
    let startTime;
    let animationFrameId;

    const animate = (timestamp) => {
      if (!startTime) startTime = timestamp;

      const elapsedTime = timestamp - startTime;
      const nextCount = increment * elapsedTime;

      if (elapsedTime < duration * 1000) {
        setCount(nextCount);
        // eslint-disable-next-line no-undef
        animationFrameId = requestAnimationFrame(animate);
      } else {
        setCount(value);
      }
    };

    // eslint-disable-next-line no-undef
    animationFrameId = requestAnimationFrame(animate);

    return () => {
      if (animationFrameId) {
        // eslint-disable-next-line no-undef
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [value, duration, increment]);

  return <span>{Math?.round(count)}</span>;
}

export default NumberCounter;
