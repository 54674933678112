import {
  BarChartOutlined,
  DeleteOutlined,
  IdcardOutlined,
  StopOutlined,
  TransactionOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Alert, Button, Col, Row } from 'antd';
import Card from 'antd/es/card/Card';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CommonBreadcrumb from '../../BreadCrumb';
import { ROUTES } from '../../common/constants';
import history from '../../historyData';
import PublishedBasicDetail from './BasicDetails';
import UserStatistics from './Statistics';
import Transaction from './Transaction';
import UserModal from './UserModal';
import { DELETE_USER, STATUS_UPDATE } from './graphql/Mutations';
import { SINGLE_USER } from './graphql/Queries';
import './user.less';

function UserEdit() {
  const [activeTabKey1, setActiveTabKey1] = useState('BasicDetail');
  const onTab1Change = (key) => {
    setActiveTabKey1(key);
  };
  const { id } = useParams();
  const [modalState, setModalState] = useState({
    open: false,
    data: null,
    mode: 'DELETE',
    loading: true,
  });

  const [, setOpen] = useState(false);

  const [fetchPerson, { data }] = useLazyQuery(SINGLE_USER, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (id) {
      fetchPerson({
        variables: { where: { id } },
      });
    }
  }, [id]);

  const [deleteuser] = useMutation(DELETE_USER, {
    onError() {},
  });

  const [userstatus] = useMutation(STATUS_UPDATE, {
    onCompleted: () => {
      fetchPerson({
        variables: { where: { id } },
      });
    },
  });

  const handleBack = () => {
    history?.goBack(ROUTES?.USERS);
  };

  const handleCancel = () => {
    setModalState({
      open: false,
      mode: 'DELETE',
      loading: true,
    });
  };

  const handleDelete = async () => {
    try {
      await deleteuser({
        variables: { where: { userId: id } },
        fetchPolicy: 'network-only',
      });
      handleBack();
    } catch (error) {
      return error;
    }
  };

  const handleUpdate = async () => {
    try {
      await userstatus({
        variables: {
          data: {
            id,
            status: data?.adminUser?.isActive ? 'DEACTIVATED' : 'ACTIVE',
          },
        },
        fetchPolicy: 'network-only',
      });
      handleBack();
    } catch (error) {
      return error;
    }
  };

  const crumb = id
    ? [
        {
          name: 'users',
        },
        {
          name: 'Edit profile',
        },
      ]
    : null;

  const tabList = [
    {
      key: 'BasicDetail',
      tab: (
        <>
          <IdcardOutlined />
          Basic Details
        </>
      ),
    },
    {
      key: 'Statistics',
      tab: (
        <>
          <BarChartOutlined />
          Statistics
        </>
      ),
    },
    {
      key: 'Transaction',
      tab: (
        <>
          <TransactionOutlined />
          Transaction
        </>
      ),
    },
  ];
  const contentList = {
    BasicDetail: (
      <PublishedBasicDetail disableForm={data?.adminUser?.isActive} />
    ),
    Statistics: <UserStatistics />,
    Transaction: <Transaction />,
  };
  const operations = (
    <>
      <Row gutter={[4, 16]}>
        <Col>
          <>
            <UserModal
              open={modalState?.open}
              mode={modalState?.mode}
              onClose={handleCancel}
              onOk={modalState?.mode === 'DELETE' ? handleDelete : handleUpdate}
            />
            <Button
              onClick={() => {
                setModalState({
                  open: true,
                  mode: 'DELETE',
                });
              }}
              className="btn-delete"
            >
              <DeleteOutlined />
              Delete
            </Button>
          </>
        </Col>
        <Col>
          {data?.adminUser?.isActive && (
            <>
              <Button
                onClick={() => {
                  setOpen(true);
                  setModalState({
                    open: true,
                    mode: 'ACTIVATE',
                  });
                }}
                type="primary"
                className="btn-activate"
              >
                <StopOutlined />
                De-activate
              </Button>
            </>
          )}
        </Col>
      </Row>
    </>
  );

  return (
    <div>
      <Card
        extra={operations}
        title={<CommonBreadcrumb crumbs={crumb} />}
        tabList={tabList}
        activeTabKey={activeTabKey1}
        onTabChange={onTab1Change}
      >
        {data?.adminUser?.isActive === false && (
          <>
            <Alert
              showIcon
              message="profile is deactivated"
              description="This profile is deactivated. Re-activate to allow user to use the app."
              type="info"
              action={
                <Button
                  className="btn-activate"
                  onClick={() => {
                    setOpen(true);
                    setModalState({
                      open: true,
                      mode: 'REACTIVATE',
                    });
                  }}
                  type="primary"
                >
                  <StopOutlined />
                  Re-activate
                </Button>
              }
            />
            <br />
          </>
        )}

        {contentList?.[activeTabKey1]}
      </Card>
    </div>
  );
}

export default UserEdit;
