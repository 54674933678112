import {
  BarChartOutlined,
  CustomerServiceOutlined,
  DeleteOutlined,
  IdcardOutlined,
  SettingOutlined,
  StopOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Card, Col, Row, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CommonBreadcrumb from '../../../BreadCrumb';
import { ROUTES } from '../../../common/constants';
import LoaderComponent from '../../../components/LoaderComponent';
import history from '../../../historyData';
import Statistics from '../drafts/Statistics';
import '../drafts/draft.less';
import BasicDetail from './BasicDetails';
import Configuration from './Configuration';
import GameAsset from './GameAsset';
import PublishedModal from './Modal';
import { DELETE_GAME, UPDATE_STATUS } from './graphql/Mutations';
import { PUBLISHED_SINGLE_GAME } from './graphql/Queries';

const formIds = {
  1: 'basic-detail-form',
  2: 'game-asset-form',
  3: 'config-form',
};

function UploadForms() {
  const { id: editId } = useParams();
  const [modalState, setModalState] = useState({
    open: false,
    data: null,
    mode: 'DELETE',
    loading: true,
  });
  const [activeKey, setActiveKey] = useState(1);
  const [loading, setLoading] = useState(false);
  const onTab1Change = (key) => {
    setActiveKey(key);
  };
  const [fetchGame, { data }] = useLazyQuery(PUBLISHED_SINGLE_GAME, {
    fetchPolicy: 'network-only',
  });

  const [deleteGame, { loading: deleteGameLoading }] = useMutation(
    DELETE_GAME,
    {
      onCompleted() {
        history?.push(ROUTES?.PUBLISH);
      },
      onError() {},
    },
  );

  const [updateGameStatus, { loading: updateStatusLoading }] = useMutation(
    UPDATE_STATUS,
    {
      onCompleted() {
        history?.push(ROUTES?.PUBLISH);
      },
      onError() {},
    },
  );

  useEffect(() => {
    if (editId) {
      fetchGame({
        variables: { where: { id: editId } },
      });
    }
  }, [editId]);

  const crumb = [
    {
      name: 'games',
      key: '1',
    },
    {
      name: `${data?.adminGame?.name}`,
      key: '2',
    },
  ];

  const handleBack = () => {
    history?.push(ROUTES?.PUBLISH);
  };

  const handleCancel = () => {
    setModalState({
      open: false,
      mode: 'DELETE',
      loading: true,
    });
  };

  const handleDelete = () => {
    deleteGame({
      variables: { where: { id: editId } },
      fetchPolicy: 'network-only',
    });
  };

  const handleUnpublish = () => {
    updateGameStatus({
      variables: {
        where: { id: editId },
        data: {
          status: 'DRAFT',
        },
      },
      fetchPolicy: 'network-only',
    });
  };
  const tabList = [
    {
      key: 1,
      tab: (
        <>
          <IdcardOutlined />
          Basic Details
        </>
      ),
    },
    {
      key: 2,
      tab: (
        <>
          <CustomerServiceOutlined />
          Game Assets
        </>
      ),
    },
    {
      key: 3,
      tab: (
        <>
          <SettingOutlined />
          Configuration
        </>
      ),
    },
    {
      key: 4,
      tab: (
        <>
          <BarChartOutlined />
          Statistics
        </>
      ),
    },
  ];
  const contentList = {
    1: (
      <LoaderComponent spinning={loading}>
        <BasicDetail
          formId={formIds?.[1]}
          setLoading={setLoading}
          editId={editId}
        />
      </LoaderComponent>
    ),
    2: (
      <LoaderComponent spinning={loading}>
        <GameAsset
          id={editId}
          formId={formIds?.[2]}
          setLoading={setLoading}
          editId={editId}
        />
      </LoaderComponent>
    ),
    3: (
      <LoaderComponent spinning={loading}>
        <Configuration
          id={editId}
          formId={formIds?.[3]}
          setLoading={setLoading}
          editId={editId}
        />
      </LoaderComponent>
    ),

    4: <Statistics editId={editId} />,
  };
  // kept for future use
  // const items = [
  //   {
  //     key: 1,
  //     label: `Basic Detail`,
  //   },
  //   {
  //     key: 2,
  //     label: `Game Assets`,
  //     children: (
  //       <LoaderComponent spinning={loading}>
  //         <GameAsset
  //           id={editId}
  //           formId={formIds?.[2]}
  //           setLoading={setLoading}
  //           editId={editId}
  //         />
  //       </LoaderComponent>
  //     ),
  //   },
  //   {
  //     key: 3,
  //     label: `Configuration`,
  //     children: (
  //       <LoaderComponent spinning={loading}>
  //         <Configuration
  //           id={editId}
  //           formId={formIds?.[3]}
  //           setLoading={setLoading}
  //           editId={editId}
  //         />
  //       </LoaderComponent>
  //     ),
  //   },
  //   {
  //     key: 4,
  //     label: `Statistics`,
  //     children: <Statistics editId={editId} />,
  //   },
  // ];

  const operations = (
    <Row gutter={[1, 16]}>
      <Col>
        <PublishedModal
          open={modalState?.open}
          mode={modalState?.mode}
          onClose={handleCancel}
          onOk={modalState?.mode === 'DELETE' ? handleDelete : handleUnpublish}
        />
        <Button
          onClick={() => {
            setModalState({
              open: true,
              mode: 'DELETE',
            });
          }}
          className="btn-delete"
        >
          <DeleteOutlined />
          Delete
        </Button>
      </Col>
      <Col>
        <Button
          onClick={() => {
            setModalState({
              open: true,
              mode: 'UNPUBLISHED',
            });
          }}
          type="primary"
          className="btn-unpublish"
        >
          <StopOutlined />
          Un-publish
        </Button>
      </Col>
    </Row>
  );
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
          <Card
            className="side-bar-card"
            title="Game"
            tabList={tabList}
            activeTabKey={activeKey}
            onTabChange={onTab1Change}
            tabProps={{
              tabPosition: 'left',
            }}
          />
        </Col>
        <Col xs={24} sm={16} md={16} lg={16} xl={16}>
          <Card
            className="card-out"
            title={<CommonBreadcrumb crumbs={crumb} />}
            extra={operations}
            activeTabKey={activeKey}
            onTabChange={onTab1Change}
            actions={
              activeKey !== 4 && [
                <div key="actionButton" className="text-center">
                  <Space>
                    <Row gutter={[16, 16]}>
                      <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Button
                          key="cancel"
                          htmlType="submit"
                          onClick={handleBack}
                          className="btn-form-cancel"
                          disabled={
                            loading || deleteGameLoading || updateStatusLoading
                          }
                        >
                          Cancel
                        </Button>
                      </Col>
                      <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Button
                          type="primary"
                          key="save"
                          htmlType="submit"
                          form={formIds?.[activeKey]}
                          className="btn-form-save"
                          loading={
                            loading || deleteGameLoading || updateStatusLoading
                          }
                        >
                          Save Changes
                        </Button>
                      </Col>
                    </Row>
                  </Space>
                </div>,
              ]
            }
          >
            {contentList?.[activeKey]}
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default UploadForms;
