import { Modal } from 'antd';
import React from 'react';

export default function UserModal({ open, mode, onOk, onClose }) {
  const handleModalClose = () => {
    onClose();
  };

  let okText;
  if (mode === 'DELETE') {
    okText = 'Yes, Delete';
  } else if (mode === 'ACTIVATE') {
    okText = 'Yes, De-activate';
  } else {
    okText = 'Yes, Re-activate';
  }

  let title;
  if (mode === 'DELETE') {
    title = 'Delete';
  } else if (mode === 'ACTIVATE') {
    title = 'De-activate';
  } else {
    title = 'Re-activate';
  }

  let Description;
  if (mode === 'DELETE') {
    Description =
      'Are you sure you want to delete this game? This will permanently remove all the records related to this game';
  } else if (mode === 'ACTIVATE') {
    Description =
      'Are you sure you want to de-activate this account? He will not be allowed to login into the application; you may re-activate it later.';
  } else {
    Description =
      'Are you sure you want to Re-activate this account? He will  be allowed to login into the application; you may de-activate it later.';
  }

  return (
    <div>
      <Modal
        title={title}
        onOk={onOk}
        okText={okText}
        open={open}
        onCancel={handleModalClose}
      >
        {Description}
      </Modal>
    </div>
  );
}
