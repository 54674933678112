import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Col, Form, Input, Typography, Upload, message } from 'antd';
import ImgCrop from 'antd-img-crop';
import { map, replace } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import client from '../../../apollo';
import { REGEX } from '../../../common/constants';
import { fileUpload, formValidatorRules } from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import { ASSET_GAME } from './graphql/Mutations';
import { IMAGE_SIGN_URL, PUBLISHED_SINGLE_GAME } from './graphql/Queries';

const { required } = formValidatorRules;
const { Title } = Typography;

function CustomUpload({ beforeUpload, children, ...rest }) {
  const handleBeforeUpload = (file, files) => {
    const isImage = file?.type?.startsWith('image/');
    if (!isImage) {
      return false;
    }

    if (typeof beforeUpload === 'function') {
      return beforeUpload(file, files);
    }
  };

  return (
    <Upload {...rest} beforeUpload={handleBeforeUpload}>
      {children}
    </Upload>
  );
}

const GameAsset = ({ formId, setLoading }) => {
  const { id } = useParams();
  const { form } = Form?.useForm();
  const [details, setDetails] = useState(null);
  const [basicLoading, setBasicLoading] = useState(false);
  const [icon, setIcon] = useState([]);
  const [logoType, setLogotype] = useState([]);
  const [background, setBackground] = useState([]);
  const [screenShot, setScreenShot] = useState([]);

  const [fetchGame, { loading }] = useLazyQuery(PUBLISHED_SINGLE_GAME, {
    fetchPolicy: 'network-only',
    onCompleted: ({ adminGame: game }) => {
      setDetails(game?.asset);

      setIcon([game?.asset?.icon]);
      setLogotype([game?.asset?.logotype]);
      setBackground([game?.asset?.backgroundImage]);
      setScreenShot(
        game?.asset?.screenshots?.map(({ url, key }) => ({ url, key })),
      );
      setBasicLoading(false);
    },
  });

  const [UpsertGameAsset, { loading: assetLoading }] = useMutation(ASSET_GAME, {
    onCompleted: () => {
      if (id) {
        fetchGame({
          variables: { where: { id } },
        });
      }
      setLoading(false);
    },
  });

  useEffect(() => {
    if (id) {
      fetchGame({
        variables: { where: { id } },
      });
    }
  }, [id]);
  const initialValues = {
    ...details,
  };

  const handleImageUpload = async (file = null) => {
    const fileName = replace(file?.name, new RegExp(' ', 'g'), '_');
    const res = await client?.query({
      query: IMAGE_SIGN_URL,
      variables: {
        data: {
          fileName,
          gameId: id,
          gameModule: file?.gameModule,
        },
      },
      onError: setLoading(false),
    });

    const { signedUrl = '', key = '' } = await res?.data?.getGameImageSignUrl;

    try {
      await fileUpload(signedUrl, file?.originFileObj);
      return { [file?.gameModule]: key };
    } catch (error) {
      message?.error(error?.message);
    }
  };

  const onFinish = async (value) => {
    setLoading(true);
    const newIcon = icon?.[0]?.url ? [] : icon;
    const newLogoType = logoType?.[0]?.url ? [] : logoType;
    const newBackgroundType = background?.[0]?.url ? [] : background;
    const newScreenShort = screenShot?.filter((item) => !item?.url);

    const files = [
      ...newIcon,
      ...newLogoType,
      ...newBackgroundType,
      ...newScreenShort,
    ];

    const finalResponse = await Promise?.all(
      map(files, (item) => handleImageUpload(item)),
    );

    const uploadedImage = screenShot
      ?.filter((item) => item?.url)
      ?.map((item) => item?.key);

    const screenshots = finalResponse
      ?.filter((item) => item?.SCREENSHOTS)
      ?.map((item) => item?.SCREENSHOTS);

    const allScreenShot = [...screenshots, ...uploadedImage];

    UpsertGameAsset({
      variables: {
        data: {
          backgroundImage: finalResponse?.find((item) => item?.BACKGROUND_IMAGE)
            ?.BACKGROUND_IMAGE,
          icon: finalResponse?.find((item) => item?.ICON)?.ICON,
          logotype: finalResponse?.find((item) => item?.LOGO_TYPE)?.LOGO_TYPE,
          ...(screenshots?.length > 0 && {
            screenshots: allScreenShot,
          }),

          youtubeUrl: value?.youtubeUrl,
        },
        where: {
          id,
        },
      },
    });
  };

  if (loading || (!details && basicLoading) || assetLoading) {
    return <LoaderComponent />;
  }
  return (
    <div>
      <>
        <Title level={5} className="mt-0 mb-24">
          Game asset
        </Title>
        <Form
          id={formId}
          layout="horizontal"
          initialValues={initialValues}
          onFinish={onFinish}
          labelCol={{
            span: 8,
          }}
          labelAlign="left"
          labelWrap
          form={form}
          disabled={assetLoading}
        >
          <Col>
            <Form.Item
              name="youtubeUrl"
              label="Trailer link"
              rules={[
                {
                  required: true,
                  message: 'Please enter trailer link!',
                },
                {
                  pattern: REGEX?.WEB_URL,
                  message: 'Please enter valid trailer link',
                },
              ]}
            >
              <Input size="large" allowClear placeholder="Enter trailer link" />
            </Form.Item>
          </Col>
          <Form.Item
            name="icon"
            label="Icon"
            rules={[
              {
                ...required,
                message: 'Please enter game icon!',
              },
            ]}
            tooltip={{
              title: 'supports only .jpeg .jpg .png',
              icon: <InfoCircleOutlined />,
            }}
          >
            <Col>
              <ImgCrop
                showGrid
                rotationSlider
                aspectSlider
                showReset
                modalOk="Crop"
              >
                <CustomUpload
                  accept="image/x-png, image/jpeg, image/jpg"
                  className="upload-image-container"
                  listType="picture-card"
                  showUploadList={{
                    showPreviewIcon: false,
                    showRemoveIcon: true,
                  }}
                  onChange={(info) => {
                    const { fileList } = info || {};
                    const file = fileList?.[0];
                    if (file) {
                      const isImage = file?.type?.startsWith('image/');
                      if (!isImage) {
                        return false;
                      }
                    }
                    setIcon(
                      map(info?.fileList, (item) => ({
                        ...item,
                        gameModule: 'ICON',
                        status: 'done',
                      })),
                    );
                  }}
                  fileList={icon}
                  maxCount={1}
                >
                  {icon?.length === 0 && (
                    <div className="d-flex align-center justify-center flex-vertical upload-content">
                      <PlusOutlined />
                      <p>Upload</p>
                    </div>
                  )}
                </CustomUpload>
              </ImgCrop>
            </Col>
          </Form.Item>
          <Form.Item
            name="logotype"
            label="Logo type"
            rules={[{ ...required, message: 'Please enter game logo type!' }]}
            tooltip={{
              title: 'supports only .jpeg .jpg .png',
              icon: <InfoCircleOutlined />,
            }}
          >
            <Col>
              <ImgCrop
                showGrid
                rotationSlider
                aspectSlider
                showReset
                modalOk="Crop"
              >
                <CustomUpload
                  accept="image/x-png, image/jpeg, image/jpg"
                  className="upload-image-container"
                  listType="picture-card"
                  showUploadList={{
                    showPreviewIcon: false,
                    showRemoveIcon: true,
                  }}
                  onChange={(info) => {
                    const { fileList } = info || {};
                    const file = fileList?.[0];
                    if (file) {
                      const isImage = file?.type?.startsWith('image/');
                      if (!isImage) {
                        return false;
                      }
                    }
                    setLogotype(
                      map(info?.fileList, (item) => ({
                        ...item,
                        gameModule: 'LOGO_TYPE',
                        status: 'done',
                      })),
                    );
                  }}
                  fileList={logoType}
                  maxCount={1}
                >
                  {logoType?.length === 0 && (
                    <div className="d-flex align-center justify-center flex-vertical upload-content">
                      <PlusOutlined />
                      <p>Upload</p>
                    </div>
                  )}
                </CustomUpload>
              </ImgCrop>
            </Col>
          </Form.Item>

          <Form.Item
            name="backgroundImage"
            label="Background image"
            rules={[
              {
                ...required,
                message: 'Please enter game background image!',
              },
            ]}
            tooltip={{
              title: 'supports only .jpeg .jpg .png',
              icon: <InfoCircleOutlined />,
            }}
          >
            <Col>
              <ImgCrop
                showGrid
                rotationSlider
                aspectSlider
                showReset
                modalOk="Crop"
              >
                <CustomUpload
                  accept="image/x-png, image/jpeg, image/jpg"
                  className="upload-image-container"
                  listType="picture-card"
                  showUploadList={{
                    showPreviewIcon: false,
                    showRemoveIcon: true,
                  }}
                  onChange={(info) => {
                    const { fileList } = info || {};
                    const file = fileList?.[0];
                    if (file) {
                      const isImage = file?.type?.startsWith('image/');
                      if (!isImage) {
                        return false;
                      }
                    }
                    setBackground(
                      map(info?.fileList, (item) => ({
                        ...item,
                        gameModule: 'BACKGROUND_IMAGE',
                        status: 'done',
                      })),
                    );
                  }}
                  fileList={background}
                  maxCount={1}
                >
                  {background?.length === 0 && (
                    <div className="d-flex align-center justify-center flex-vertical upload-content">
                      <PlusOutlined />
                      <p>Upload</p>
                    </div>
                  )}
                </CustomUpload>
              </ImgCrop>
            </Col>
          </Form.Item>

          <Form.Item
            name="screenshots"
            label="Screenshots"
            rules={[
              { ...required, message: 'Please enter game play screenshot!' },
            ]}
            tooltip={{
              title: 'supports only .jpeg .jpg .png',
              icon: <InfoCircleOutlined />,
            }}
          >
            <Col>
              <ImgCrop
                showGrid
                rotationSlider
                aspectSlider
                showReset
                modalOk="Crop"
              >
                <CustomUpload
                  accept="image/x-png, image/jpeg, image/jpg"
                  className="upload-image-container"
                  listType="picture-card"
                  showUploadList={{
                    showPreviewIcon: false,
                    showRemoveIcon: true,
                  }}
                  onChange={(info) => {
                    const { fileList } = info || {};
                    const newFileList = fileList?.filter(
                      (obj) => obj?.type?.startsWith('image/') || 'url' in obj,
                    );
                    if (!newFileList) {
                      return false;
                    }
                    setScreenShot(
                      map(newFileList, (item) => ({
                        ...item,
                        gameModule: 'SCREENSHOTS',
                        status: 'done',
                      })),
                    );
                  }}
                  fileList={screenShot}
                  maxCount={4}
                >
                  {screenShot?.length < 4 && (
                    <div className="d-flex align-center justify-center flex-vertical upload-content">
                      <PlusOutlined />
                      <p>Upload</p>
                    </div>
                  )}
                </CustomUpload>
              </ImgCrop>
            </Col>
          </Form.Item>
        </Form>
      </>
    </div>
  );
};

export default GameAsset;
