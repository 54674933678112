import { useLazyQuery } from '@apollo/client';
import { Table, Typography } from 'antd';
import { camelCase, startCase } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { defaultDateFormat } from '../../common/constants';
import { SINGLE_USER_TRANSACTION } from './graphql/Queries';

export const getCurrent = (skip, limit) => skip / limit + 1;
const { Title } = Typography;

export default function Transaction() {
  const { id } = useParams();
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [sortOn] = useState('amount');
  const [sortBy] = useState('ASC');

  const [getUserList, { loading, data }] = useLazyQuery(
    SINGLE_USER_TRANSACTION,
    {
      variables: {
        filter: { skip, limit },
        sort: {
          sortOn,
          sortBy,
        },
      },
      fetchPolicy: 'network-only',
    },
  );

  useEffect(() => {
    if (id) {
      getUserList({
        variables: {
          filter: { skip, limit },
          sort: {
            sortOn,
            sortBy,
          },

          where: {
            userId: id,
          },
        },
      });
    }
  }, [skip, limit, sortOn, sortBy, id]);

  const UserData = data?.adminTransactions?.data;
  const total = data?.adminTransactions?.count;

  const columns = [
    {
      title: 'Transaction Type',
      dataIndex: 'transactionType',
      key: 'transactionType',
      width: '15%',
      render: (_, record) => startCase(camelCase(record?.transactionType)),
    },
    {
      title: 'Transaction ID',
      dataIndex: 'id',
      key: 'id',
      width: '25%',
    },
    {
      title: 'Coins',
      dataIndex: 'amount',
      key: 'amount',
      width: '10%',
    },
    {
      title: 'Transaction Charges',
      dataIndex: '',
      key: 'transactionType',
      width: '15%',
    },

    {
      title: 'Transaction Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '20%',
      render: (createdAt) =>
        moment(createdAt)?.format(`${defaultDateFormat} hh:mm a`),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      render: (_, record) => startCase(camelCase(record?.status)),
    },
  ];
  const handleTableChange = ({ current, pageSize }, _, _1, { action }) => {
    switch (action) {
      case 'paginate': {
        setSkip(current * pageSize - pageSize);
        setLimit(pageSize);
        break;
      }
      default:
        break;
    }
  };
  return (
    <div className="transaction-site-card">
      <Title level={5} className="mt-0 mb-24">
        Transaction
      </Title>
      <Table
        columns={columns}
        dataSource={UserData}
        rowKey="id"
        onChange={handleTableChange}
        pagination={{
          position: ['bottomCenter'],
          total,
          current: getCurrent(skip, limit),
          pageSize: limit,
        }}
        loading={loading}
        scroll={{ x: 800, y: 500 }}
      />
    </div>
  );
}
