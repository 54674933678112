import { InfoCircleOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Col, Form, InputNumber, Radio, Row, Select, Typography } from 'antd';
import { uniqueId } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { ROUTES } from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import history from '../../../historyData';
import './draft.less';
import { GAME_CONFIGURATION, UPDATE_STATUS } from './graphql/Mutation';
import { ADMIN_SINGLE_GAME } from './graphql/Query';

const { required } = formValidatorRules;
const { Title } = Typography;

const Configuration = ({ id, formId, setLoading, editId }) => {
  const [UpdateGameStatus] = useMutation(UPDATE_STATUS);
  const { pathname } = useLocation();
  const isEdit = pathname?.split('/')?.[2];
  const [UpsertGameConfiguration] = useMutation(GAME_CONFIGURATION, {
    onCompleted: () => {
      if (editId && isEdit === 'edit') {
        UpdateGameStatus({
          variables: {
            where: {
              id: editId,
            },
            data: {
              status: 'PUBLISHED',
            },
          },
        });
      }
      history?.push(ROUTES?.DRAFTS);
    },
    onError() {
      setLoading(false);
    },
  });

  const valuesInitial = {
    gameTime: '',
    findOpponentsTime: '',
    live: '',
    isOpponentsRequired: false,
    winingAmountMargin: '',
    playFree: true,
    coinOption: [],
  };

  const [form] = Form?.useForm();
  const playFree = Form?.useWatch('playFree', form);
  const [configLoading, setConfigLoading] = useState(true);
  const [details, setDetails] = useState(valuesInitial);
  const [fetchGame, { loading: fetchLoading }] = useLazyQuery(
    ADMIN_SINGLE_GAME,
    {
      fetchPolicy: 'network-only',
      onCompleted: ({ adminGame: game }) => {
        setDetails(game?.config === null ? details : game?.config);
        setConfigLoading(false);
      },
      onError() {
        setLoading(false);
      },
    },
  );

  useEffect(() => {
    if (editId) {
      fetchGame({
        variables: { where: { id: editId } },
      });
    }
  }, [editId]);

  const initialValues = !editId
    ? valuesInitial
    : {
        ...details,
      };
  const onFinish = (values) => {
    setLoading(true);
    const coinOptions = values?.coinOption?.map((option) =>
      parseInt(option, uniqueId),
    );
    UpsertGameConfiguration({
      variables: {
        data: { ...values, coinOption: coinOptions },

        where: {
          id: editId || id,
        },
      },
    });
  };

  if ((!details && fetchLoading) || (editId && configLoading)) {
    return <LoaderComponent />;
  }
  return (
    <div>
      <Title level={5} className="mt-0 mb-24">
        Configuration
      </Title>
      <Form
        id={formId}
        layout="vertical"
        initialValues={initialValues}
        onFinish={onFinish}
        form={form}
      >
        <Row gutter={[24, 24]}>
          <Col xs={24} sm={20} md={16} lg={8} xl={8}>
            <Form.Item
              name="gameTime"
              label="Time for the game"
              tooltip={{
                title: 'Time for the game is in seconds',
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  ...required,
                  message: 'Please enter time for the game!',
                },
                {
                  message: 'Game time should more than 30!',
                  validator: (_, value) => {
                    if (value && value < 30) {
                      return Promise?.reject();
                    }
                    return Promise?.resolve();
                  },
                },
              ]}
            >
              <InputNumber
                size="large"
                min={1}
                className="input"
                formatter={(value) => `${value}s`}
                parser={(value) => value?.replace('s', '')}
                placeholder="Enter time for the game"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={20} md={16} lg={10} xl={10}>
            <Form.Item
              name="findOpponentsTime"
              label="Maximum time to find opponents"
              tooltip={{
                title:
                  'Time is in seconds (recommend time between 30s to 120s)',
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  ...required,
                  message: 'Please enter max time to find opponent!',
                },
                {
                  message: 'Find opponent time should be more than 30!',
                  validator: (_, value) => {
                    if (value && value < 30) {
                      return Promise?.reject();
                    }
                    return Promise?.resolve();
                  },
                },
              ]}
            >
              <InputNumber
                size="large"
                min={1}
                className="input"
                formatter={(value) => `${value}s`}
                parser={(value) => value?.replace('s', '')}
                placeholder="Enter max time to find opponent"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={20} md={16} lg={8} xl={8}>
            <Form.Item
              name="live"
              label="Total number of lives"
              rules={[
                {
                  ...required,
                  message: 'Please enter total number of lives!',
                },
              ]}
            >
              <InputNumber
                size="large"
                min={1}
                className="input"
                placeholder="Enter total number of lives"
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item
              name="isOpponentsRequired"
              label="Allow game to start before all the opponents join"
              rules={[{ ...required, message: 'Please select start before!' }]}
            >
              <Radio.Group>
                <Row gutter={16}>
                  <Col>
                    <Radio value>Yes</Radio>
                  </Col>
                  <Col>
                    <Radio value={false}>No</Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={20} md={16} lg={8} xl={8}>
            <Form.Item
              name="winingAmountMargin"
              label="Margin from winning amount (%)"
              rules={[
                {
                  ...required,
                  message: 'Please enter margin from winning amount!',
                },
              ]}
            >
              <InputNumber
                size="large"
                min={1}
                formatter={(value) => `${value}%`}
                parser={(value) => value?.replace('%', '')}
                className="input"
                placeholder="Enter wining amount margin"
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item
              name="playFree"
              label="Allow user to play game for free"
              rules={[{ ...required, message: 'Please select free play!' }]}
            >
              <Radio.Group>
                <Row gutter={16}>
                  <Col>
                    <Radio value>Yes</Radio>
                  </Col>
                  <Col>
                    <Radio value={false}>No</Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col xs={24} lg={8}>
            <Form.Item
              name="coinOption"
              label="Coin options to be shown to user"
              rules={[
                { ...required, message: 'Please select coin options!' },
                {
                  validator: (_, value) => {
                    if (value) {
                      if (playFree && value?.length > 0) {
                        if (value?.length > 3) {
                          return Promise?.reject(
                            new Error('Only 3 values should be passed'),
                          );
                        }
                        if (value?.length < 3) {
                          return Promise?.reject(
                            new Error('3 coin values are required'),
                          );
                        }
                      }
                      if (!playFree && value?.length > 0) {
                        if (value?.length > 4) {
                          return Promise?.reject(
                            new Error('Only 4 values should be passed'),
                          );
                        }
                        if (value?.length < 4) {
                          return Promise?.reject(
                            new Error('4 coin values are required'),
                          );
                        }
                      }
                      return Promise?.resolve();
                    }
                  },
                },
              ]}
            >
              <Select
                mode="tags"
                allowClear
                size="large"
                placeholder="Select coin options"
                maxTagCount={playFree ? 3 : 4}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Configuration;
