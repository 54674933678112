import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation AdminLogin($data: AdminLoginInput!) {
    adminLogin(data: $data) {
      data {
        name
        email
        createdAt
        updatedAt
        id
      }
      token
      refreshToken
      message
    }
  }
`;

export const SIGNUP = gql`
  mutation AdminLogout {
    adminLogout {
      message
    }
  }
`;

export const LOGOUT = gql`
  mutation AdminLogout {
    adminLogout {
      message
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation emailPasswordForgotPassword(
    $data: EmailPasswordForgotPasswordData!
  ) {
    emailPasswordForgotPassword(data: $data) {
      message
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation emailPasswordResetPassword($data: EmailPasswordResetPasswordData!) {
    emailPasswordResetPassword(data: $data) {
      message
    }
  }
`;

export const VERIFY_TOKEN = gql`
  mutation emailPasswordVerifyResetToken($token: String!) {
    emailPasswordVerifyResetToken(data: { token: $token }) {
      message
      data {
        isValidToken
      }
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation RefreshToken($data: RefreshTokenInput!) {
    refreshToken(data: $data) {
      user {
        id
        name
        createdAt
        updatedAt
      }
      accessToken
    }
  }
`;

export const UPDATE_CURRENT_USER = gql`
  mutation updateCurrentUser($data: UpdateCurrentUserInput!) {
    updateCurrentUser(data: $data) {
      message
      data {
        id
        email
        profileImage
        name
        firstName
        lastName
      }
    }
  }
`;
