import { gql } from '@apollo/client';

const UPDATE_CONFIG = gql`
  mutation UpdateConfiguration(
    $where: UpdateConfigurationInput!
    $data: ConfigurationInput!
  ) {
    updateConfiguration(where: $where, data: $data) {
      data {
        id
        key
        name
        type
        value
        createdAt
        updatedAt
      }
      message
    }
  }
`;

export default UPDATE_CONFIG;
