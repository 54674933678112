import { gql } from '@apollo/client';

const CONFIG = gql`
  query Configurations(
    $filter: ConfigurationsFilter
    $sort: ConfigurationsSort
  ) {
    configurations(filter: $filter, sort: $sort) {
      data {
        createdAt
        id
        key
        name
        type
        updatedAt
        value
      }
    }
  }
`;
export default CONFIG;
