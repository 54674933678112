import { useLazyQuery } from '@apollo/client';
import { Card, Select, Table } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CommonBreadcrumb from '../../BreadCrumb';
import {
  LIMIT,
  defaultDateFormat,
  leaderboardDateRange,
} from '../../common/constants';
import LoaderComponent from '../../components/LoaderComponent';
import SearchComponent from '../../components/SearchComponent';
import {
  ADMIN_LEADERBOARD,
  ADMIN_SINGLE_GAME,
} from '../game/drafts/graphql/Query';

export const getCurrent = (skip, limit) => skip / limit + 1;

export default function GameLeaderboard() {
  const { id } = useParams();
  const [limit, setLimit] = useState(LIMIT);
  const [skip, setSkip] = useState(0);
  const [search, setSearch] = useState('');
  const [leaderData, setLeaderData] = useState();
  const [gameTitle, setGameTitle] = useState('');
  const [date, setDate] = useState(null);

  const initialLeaderboardFilter = {
    where: {
      gameId: id,
    },
    filter: {
      date,
      limit,
      skip,
      search,
    },
  };

  const [
    fetchLeaderboard,
    { data, loading: leaderboardLoading },
  ] = useLazyQuery(ADMIN_LEADERBOARD, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setLeaderData(res?.adminLeaderBoard?.data);
    },
  });

  const [fetchSingleGame, { loading: singleGameLoading }] = useLazyQuery(
    ADMIN_SINGLE_GAME,
    {
      fetchPolicy: 'network-only',
      onCompleted: (newRes) => {
        setGameTitle(newRes?.adminGame?.name);
      },
    },
  );

  useEffect(() => {
    fetchLeaderboard({
      variables: initialLeaderboardFilter,
    });
  }, [skip, limit, date, search]);
  useEffect(() => {
    fetchSingleGame({
      variables: {
        where: {
          id,
        },
      },
    });
  }, []);

  const handleSearchChange = (text) => {
    setSearch(text);
  };
  const handleTableChange = ({ current, pageSize }, _1, _, { action }) => {
    switch (action) {
      case 'paginate': {
        setSkip(current * pageSize - pageSize);
        setLimit(pageSize);
        break;
      }

      default:
        break;
    }
  };
  const headerClassSelectors = [
    'header',
    '.ant-card .ant-card-head',
    '.ant-table-thead',
    '.ant-table-wrapper .ant-table-pagination.ant-pagination',
    'footer',
  ]; // Replace with your class selectors
  const headerHeight = headerClassSelectors?.reduce((totalHeight, selector) => {
    // eslint-disable-next-line no-undef
    const headerElement = document?.querySelector(selector);
    if (headerElement) {
      return totalHeight + headerElement?.clientHeight;
    }
    return totalHeight;
  }, 0);

  const column = [
    {
      dataIndex: 'name',
      title: 'Display Name',
      keys: 'name',
      width: '20%',
      fixed: 'left',
      render: (_, res) => res?.user?.name,
    },
    {
      dataIndex: 'email',
      title: 'Email',
      keys: 'email',
      render: (_, record) => record?.user?.email,
    },
    {
      dataIndex: 'highestScore',
      title: 'Highest Score',
      keys: 'highestScore',
    },
    {
      dataIndex: 'lastLogin',
      title: 'Last Active',
      keys: 'lastLogin',
      render: (_, record) =>
        moment(record?.user?.lastLogin)?.format(`${defaultDateFormat} hh:mm a`),
    },
  ];

  const dataCount = data?.adminLeaderBoard?.count;

  const crumb = [
    {
      name: 'leaderboard',
    },
    {
      name: gameTitle,
    },
  ];

  if (singleGameLoading) {
    return <LoaderComponent />;
  }
  return (
    <div>
      <Card
        className="card-body-wrapper page-card"
        title={<CommonBreadcrumb crumbs={crumb} />}
        extra={
          <div className="d-flex gap-4">
            <SearchComponent getData={handleSearchChange} />
            <Select
              placeholder="Select date range"
              onChange={(value) => {
                setDate(value);
              }}
              options={leaderboardDateRange}
              allowClear
              className="full-width"
            />
          </div>
        }
      >
        <Table
          tableLayout="fixed"
          dataSource={leaderData}
          columns={column}
          rowKey="id"
          loading={leaderboardLoading}
          className="game-leader-board"
          onChange={handleTableChange}
          scroll={{
            y: `calc(100vh - ${headerHeight + 100}px)`,
          }}
          pagination={{
            position: ['bottomCenter'],
            dataCount,
            current: getCurrent(skip, limit),
            pageSize: limit,
          }}
        />
      </Card>
    </div>
  );
}
