import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Error404 from '../Error404';
import { ROUTES } from '../common/constants';
import ConfigurationWrapper from '../modules/configuration';
import DashboardWrapper from '../modules/dashboard';
import DraftWrapper from '../modules/game/drafts';
import PublishWrapper from '../modules/game/published';
import LeaderboardWrapper from '../modules/leaderboard';
import ProfileWrapper from '../modules/profile';
import UserWrapper from '../modules/users';

const ContentRoutes = () => (
  <Switch>
    <Route path={ROUTES?.MAIN} exact component={DashboardWrapper} />
    <Route path={ROUTES?.PROFILE} component={ProfileWrapper} />
    <Route path={ROUTES?.USERS} component={UserWrapper} />
    <Route path={ROUTES?.DRAFTS} component={DraftWrapper} />
    <Route path={ROUTES?.PUBLISH} component={PublishWrapper} />
    <Route path={ROUTES?.LEADERBOARD} component={LeaderboardWrapper} />
    {/*
      kept for future use
    <Route path={ROUTES?.WITHDRAWALS} component={WithdrawalsWrapper} /> */}
    <Route path={ROUTES?.CONFIGURATION} component={ConfigurationWrapper} />

    <Route path="*" exact component={Error404} />
  </Switch>
);

export default ContentRoutes;
