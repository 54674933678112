import { PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Typography,
  Upload,
  message,
} from 'antd';
import { map, replace } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import client from '../../apollo';
import { ROUTES, defaultDateFormat } from '../../common/constants';
import { fileUpload, formValidatorRules, getBase64 } from '../../common/utils';
import LoaderComponent from '../../components/LoaderComponent';
import history from '../../historyData';
import { UPDATE_USER } from './graphql/Mutations';
import { SINGLE_USER, USER_IMAGE_SIGN_URL } from './graphql/Queries';
import './user.less';

const { required } = formValidatorRules;
const { Title } = Typography;

const PublishedBasicDetail = (props) => {
  const { disableForm } = props;
  const { id } = useParams();
  const [form] = Form?.useForm();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [user, setUser] = useState(null);
  const [userImage, setUserImage] = useState([]);

  const [getUser, { loading: fetchUserLoading }] = useLazyQuery(SINGLE_USER, {
    onCompleted: ({ adminUser: users }) => {
      setUser(users);
      setUserImage([
        {
          url: users?.profileImage?.url,
        },
      ]);
    },
    fetchPolicy: 'network-only',
  });

  const [updateUser, { loading: updateUserLoading }] = useMutation(
    UPDATE_USER,
    {
      onError() {},
    },
  );

  useEffect(() => {
    if (id) {
      getUser({
        variables: { where: { id } },
      });
    }
  }, [id]);

  const initialValues = {
    ...user,
    createdAt: user?.createdAt
      ? moment(user?.createdAt)?.format(`${defaultDateFormat} hh:mm a`)
      : null,
    lastLogin: user?.lastLogin
      ? moment(user?.lastLogin)?.format(`${defaultDateFormat} hh:mm a`)
      : null,
  };

  const handleImageUpload = async (file = null) => {
    const fileName = replace(file?.name, new RegExp(' ', 'g'), '_');
    const res = await client?.query({
      query: USER_IMAGE_SIGN_URL,
      variables: {
        data: {
          fileName,
          userId: id,
        },
      },
    });

    const { signedUrl = '' } = await res?.data?.getAdminProfileSignUrl;
    try {
      await fileUpload(signedUrl, file?.originFileObj);
      return { key: res?.data?.getAdminProfileSignUrl?.key };
    } catch (error) {
      message?.error(error?.message);
    }
  };

  const handleSubmit = async (values) => {
    const newImage = userImage?.[0]?.url ? [] : userImage;

    const files = [...newImage];

    const finalResponse = await Promise?.all(
      files?.map((item) => handleImageUpload(item)),
    );

    if (id) {
      updateUser({
        variables: {
          data: {
            name: values?.name,
            profileImage: finalResponse?.[0]?.key,
          },
          where: { userId: id },
        },
      });
    }
  };

  const handlePreview = async (file) => {
    let preview;
    if (!file?.url && !file?.preview) {
      preview = await getBase64(file?.originFileObj);
    }
    setPreviewImage(file?.url || preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file?.name || file?.url?.substring(file?.url?.lastIndexOf('/') + 1),
    );
  };

  const handleCancel = () => setPreviewOpen(false);

  if (fetchUserLoading) return <LoaderComponent />;
  return (
    <div>
      <Title level={5} className="mt-0 mb-24">
        Basic Detail
      </Title>
      <Form
        form={form}
        layout="vertical"
        initialValues={user ? initialValues : null}
        onFinish={handleSubmit}
        disabled={!disableForm || updateUserLoading}
        loading={fetchUserLoading}
      >
        <Form.Item name="profileImage" label="Profile picture">
          <Upload
            accept="image/x-png, image/jpeg, image/jpg"
            className="upload-image-container"
            listType="picture-circle"
            showUploadList={{
              showPreviewIcon: true,
              showRemoveIcon: true,
            }}
            onPreview={handlePreview}
            onChange={(info) => {
              setUserImage(
                map(info?.fileList, (item) => ({
                  ...item,
                })),
              );
            }}
            beforeUpload={() => false}
            fileList={userImage}
            maxCount={1}
          >
            {userImage?.length === 0 && (
              <div className="d-flex align-center justify-center flex-vertical upload-content">
                <PlusOutlined />
                <p>Upload</p>
              </div>
            )}
          </Upload>
        </Form.Item>
        <Modal
          open={previewOpen}
          title={previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <img alt="example" className="width-100" src={previewImage} />
        </Modal>
        <Row gutter={[16, 16]}>
          <Col xs={24} lg={12}>
            <Form.Item
              name="name"
              label="Display name"
              rules={[{ ...required, message: 'Please enter display name!' }]}
            >
              <Input allowClear size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              name="email"
              label="Email"
              rules={[{ ...required, message: 'Please enter email!' }]}
            >
              <Input
                allowClear
                min={0}
                size="large"
                className="width-100"
                disabled
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} lg={12}>
            <Form.Item name="location" label="Location">
              <Input
                disabled
                type="text"
                placeholder="Enter Your Address"
                size="large"
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item name="referralCode" label="Referral code">
              <Input disabled type="text" size="large" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} lg={12}>
            <Form.Item name="createdAt" label="Profile created on">
              <Input size="large" className="width-100" disabled />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item name="lastLogin" label="Last active">
              <Input size="large" className="width-100" disabled />
            </Form.Item>
          </Col>
        </Row>
        <div key="actionButton" className="text-center">
          <Space>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Button
                  className="btn-delete"
                  onClick={() => {
                    history?.push(ROUTES?.USERS);
                  }}
                  disabled={updateUserLoading}
                >
                  Cancel
                </Button>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Button
                  className="btn-delete"
                  type="primary"
                  loading={updateUserLoading}
                  htmlType="submit"
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Space>
        </div>
      </Form>
    </div>
  );
};

export default PublishedBasicDetail;
