import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Col, Form, Input, Typography, Upload, message } from 'antd';

import ImgCrop from 'antd-img-crop';
import { map, replace } from 'lodash';
import React, { useEffect, useState } from 'react';
import client from '../../../apollo';
import { REGEX } from '../../../common/constants';
import { fileUpload, formValidatorRules } from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import './draft.less';
import { GAME_ASSET } from './graphql/Mutation';
import { ADMIN_SINGLE_GAME, IMAGE_SIGN_URL } from './graphql/Query';

const { required } = formValidatorRules;
const { Title } = Typography;

function CustomUpload({ beforeUpload, children, ...rest }) {
  const handleBeforeUpload = (file, files) => {
    const isImage = file?.type?.startsWith('image/');
    if (!isImage) {
      return false;
    }

    if (typeof beforeUpload === 'function') {
      return beforeUpload(file, files);
    }
  };

  return (
    <Upload {...rest} beforeUpload={handleBeforeUpload}>
      {children}
    </Upload>
  );
}

const GameAsset = ({ id, formId, onDone, setLoading, editId }) => {
  const { form } = Form?.useForm();
  const [details, setDetails] = useState(null);
  const [icon, setIcon] = useState([]);
  const [logoType, setLogotype] = useState([]);
  const [background, setBackground] = useState([]);
  const [screenShot, setScreenShot] = useState([]);

  const [fetchGame, { loading: gameAssetLoading }] = useLazyQuery(
    ADMIN_SINGLE_GAME,
    {
      fetchPolicy: 'network-only',
      onCompleted: ({ adminGame: game }) => {
        setDetails(game?.asset);
        setIcon(game?.asset ? [game?.asset?.icon] : []);
        setLogotype(game?.asset ? [game?.asset?.logotype] : []);
        setBackground(game?.asset ? [game?.asset?.backgroundImage] : []);
        setScreenShot(
          game?.asset
            ? game?.asset?.screenshots?.map(({ url, key }) => ({ url, key }))
            : [],
        );
        setLoading(false);
      },
    },
  );

  const [UpsertGameAsset, { loading: assetUploadLoading }] = useMutation(
    GAME_ASSET,
    {
      onCompleted: () => {
        if (editId) {
          fetchGame({
            variables: { where: { id: editId } },
          });
        }
        onDone(setLoading(false));
      },
      onError() {
        setLoading(false);
      },
    },
  );
  useEffect(() => {
    if (editId) {
      fetchGame({
        variables: { where: { id: editId } },
      });
    }
  }, [editId]);
  const initialValues = {
    ...details,
  };

  const handleImageUpload = async (file = null) => {
    const fileName = replace(file?.name, new RegExp(' ', 'g'), '_');
    const res = await client?.query({
      query: IMAGE_SIGN_URL,
      variables: {
        data: {
          fileName,
          gameId: editId || id,
          gameModule: file?.gameModule,
        },
      },
      onError: setLoading(false),
    });

    const { signedUrl = '', key = '' } = await res?.data?.getGameImageSignUrl;

    try {
      await fileUpload(signedUrl, file?.originFileObj);
      return { [file?.gameModule]: key };
    } catch (error) {
      message?.error(error?.message);
    }
  };

  const onFinish = async (value) => {
    setLoading(true);
    const newIcon = icon?.[0]?.url ? [] : icon;
    const newLogoType = logoType?.[0]?.url ? [] : logoType;
    const newBackgroundType = background?.[0]?.url ? [] : background;
    const newScreenShort = screenShot?.filter((item) => !item?.url);

    const files = [
      ...newIcon,
      ...newLogoType,
      ...newBackgroundType,
      ...newScreenShort,
    ];

    const finalResponse = await Promise?.all(
      map(files, (item) => handleImageUpload(item)),
    );

    const uploadedImage = screenShot
      ?.filter((item) => item?.url)
      ?.map((item) => item?.key);

    const screenshots = finalResponse
      ?.filter((item) => item?.SCREENSHOTS)
      ?.map((item) => item?.SCREENSHOTS);

    const allScreenShot = [...screenshots, ...uploadedImage];

    UpsertGameAsset({
      variables: {
        data: {
          icon: finalResponse?.find((item) => item?.ICON)?.ICON,
          backgroundImage: finalResponse?.find((item) => item?.BACKGROUND_IMAGE)
            ?.BACKGROUND_IMAGE,
          logotype: finalResponse?.find((item) => item?.LOGO_TYPE)?.LOGO_TYPE,
          ...(screenshots?.length > 0 && {
            screenshots: allScreenShot,
          }),

          youtubeUrl: value?.youtubeUrl,
        },
        where: {
          id: editId || id,
        },
      },
    });
  };

  if (gameAssetLoading || assetUploadLoading) {
    setLoading(true);
    return <LoaderComponent />;
  }
  return (
    <div>
      <Title level={5} className="mt-0 mb-24">
        Game Assets
      </Title>
      <Form
        id={formId}
        layout="horizontal"
        initialValues={initialValues}
        onFinish={onFinish}
        labelCol={{
          span: 8,
        }}
        labelAlign="left"
        labelWrap
        form={form}
      >
        <Col>
          <Form.Item
            name="youtubeUrl"
            label="Trailer link"
            rules={[
              {
                required: true,
                message: 'Please enter trailer link!',
              },
              {
                pattern: REGEX?.WEB_URL,
                message: 'Please enter valid trailer link',
              },
            ]}
          >
            <Input size="large" placeholder="Enter trailer link" />
          </Form.Item>
        </Col>
        <Form.Item
          name="icon"
          label="Icon"
          rules={[
            {
              ...required,
              message: 'Please enter game icon!',
            },
          ]}
          tooltip={{
            title: 'supports only .jpeg .jpg .png',
            icon: <InfoCircleOutlined />,
          }}
        >
          <Col>
            <ImgCrop
              showGrid
              rotationSlider
              aspectSlider
              showReset
              modalOk="Crop"
            >
              <CustomUpload
                accept="image/x-png, image/jpeg, image/jpg"
                className="upload-image-container"
                listType="picture-card"
                showUploadList={{
                  showPreviewIcon: false,
                  showRemoveIcon: true,
                }}
                onChange={(info) => {
                  const { fileList } = info || {};
                  const file = fileList?.[0];
                  if (file) {
                    const isImage = file?.type?.startsWith('image/');
                    if (!isImage) {
                      return false;
                    }
                  }
                  setIcon(
                    map(info?.fileList, (item) => ({
                      ...item,
                      gameModule: 'ICON',
                      status: 'done',
                    })),
                  );
                }}
                maxCount={1}
                fileList={icon}
              >
                {icon?.length < 1 && (
                  <div className="d-flex align-center justify-center flex-vertical upload-content">
                    <PlusOutlined />
                    <p>Upload</p>
                  </div>
                )}
              </CustomUpload>
            </ImgCrop>
          </Col>
        </Form.Item>
        <Form.Item
          name="logotype"
          label="Logo type"
          rules={[{ ...required, message: 'Please enter game logo type!' }]}
          tooltip={{
            title: 'supports only .jpeg .jpg .png',
            icon: <InfoCircleOutlined />,
          }}
        >
          <Col>
            <ImgCrop
              showGrid
              rotationSlider
              aspectSlider
              showReset
              modalOk="Crop"
              onModalCancel={() => setLogotype([])}
            >
              <CustomUpload
                accept="image/x-png, image/jpeg, image/jpg"
                className="upload-image-container"
                listType="picture-card"
                showUploadList={{
                  showPreviewIcon: false,
                  showRemoveIcon: true,
                }}
                onChange={(info) => {
                  const { fileList } = info || {};
                  const file = fileList?.[0];
                  if (file) {
                    const isImage = file?.type?.startsWith('image/');
                    if (!isImage) {
                      return false;
                    }
                  }
                  setLogotype(
                    map(info?.fileList, (item) => ({
                      ...item,
                      gameModule: 'LOGO_TYPE',
                      status: 'done',
                    })),
                  );
                }}
                fileList={logoType}
                maxCount={1}
              >
                {logoType?.length < 1 && (
                  <div className="d-flex align-center justify-center flex-vertical upload-content">
                    <PlusOutlined />
                    <p>Upload</p>
                  </div>
                )}
              </CustomUpload>
            </ImgCrop>
          </Col>
        </Form.Item>

        <Form.Item
          name="backgroundImage"
          label="Background image"
          rules={[
            {
              ...required,
              message: 'Please enter game background image!',
            },
          ]}
          tooltip={{
            title: 'supports only .jpeg .jpg .png',
            icon: <InfoCircleOutlined />,
          }}
        >
          <Col>
            <ImgCrop
              showGrid
              rotationSlider
              aspectSlider
              showReset
              modalOk="Crop"
            >
              <CustomUpload
                accept="image/x-png, image/jpeg, image/jpg"
                className="upload-image-container"
                listType="picture-card"
                showUploadList={{
                  showPreviewIcon: false,
                  showRemoveIcon: true,
                }}
                onChange={(info) => {
                  const { fileList } = info || {};
                  const file = fileList?.[0];
                  if (file) {
                    const isImage = file?.type?.startsWith('image/');
                    if (!isImage) {
                      return false;
                    }
                  }
                  setBackground(
                    map(info?.fileList, (item) => ({
                      ...item,
                      gameModule: 'BACKGROUND_IMAGE',
                      status: 'done',
                    })),
                  );
                }}
                fileList={background}
                maxCount={1}
              >
                {background?.length === 0 && (
                  <div className="d-flex align-center justify-center flex-vertical upload-content">
                    <PlusOutlined />
                    <p>Upload</p>
                  </div>
                )}
              </CustomUpload>
            </ImgCrop>
          </Col>
        </Form.Item>

        <Form.Item
          name="screenshots"
          label="Screenshots"
          rules={[
            { ...required, message: 'Please enter game play screenshot!' },
          ]}
          tooltip={{
            title: 'supports only .jpeg .jpg .png',
            icon: <InfoCircleOutlined />,
          }}
        >
          <Col>
            <ImgCrop
              showGrid
              rotationSlider
              aspectSlider
              showReset
              modalOk="Crop"
            >
              <CustomUpload
                accept="image/x-png, image/jpeg, image/jpg"
                className="upload-image-container"
                listType="picture-card"
                showUploadList={{
                  showPreviewIcon: false,
                  showRemoveIcon: true,
                }}
                onChange={(info) => {
                  const { fileList } = info || {};
                  const newFileList = fileList?.filter(
                    (obj) => obj?.type?.startsWith('image/') || 'url' in obj,
                  );
                  if (!newFileList) {
                    return false;
                  }

                  setScreenShot(
                    map(newFileList, (item) => ({
                      ...item,
                      gameModule: 'SCREENSHOTS',
                      status: 'done',
                    })),
                  );
                }}
                maxCount={4}
                fileList={screenShot}
              >
                {screenShot?.length < 4 && (
                  <div className="d-flex align-center justify-center flex-vertical upload-content">
                    <PlusOutlined />
                    <p>Upload</p>
                  </div>
                )}
              </CustomUpload>
            </ImgCrop>
          </Col>
        </Form.Item>
      </Form>
    </div>
  );
};

export default GameAsset;
