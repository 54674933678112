import { Button, Layout } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../AppContext';
import { MenuCloseIcon, MenuIcon } from '../assets/svg';
import { BREAKPOINTS, ROUTES } from '../common/constants';
import RouterPrompt from '../components/RouterPrompt';
import '../styles/main.less';
import './App.css';
import ContentRoutes from './ContentRoutes';
import AppHeader from './components/header/AppHeader';
import UserProfile from './components/header/UserProfile';
import Sidebar from './components/sidebar/Sidebar';

const { Content, Sider } = Layout;
const App = () => {
  const { state } = useContext(AppContext);
  const [isDesktop, setDesktop] = useState(
    // eslint-disable-next-line no-undef
    window?.innerWidth > BREAKPOINTS?.tablet,
  );
  const [isActive, setActive] = useState(false);

  const [collapsed, setCollapsed] = useState(false);
  const handleOverlay = () => {
    setActive(!isActive);
  };
  const handleToggle = () => {
    setCollapsed(!collapsed);
  };
  useEffect(() => {
    const updateMedia = () => {
      // eslint-disable-next-line no-undef
      setDesktop(window?.innerWidth > BREAKPOINTS?.tablet);
    };
    // eslint-disable-next-line no-undef
    window?.addEventListener('resize', updateMedia);
    // eslint-disable-next-line no-undef
    return () => window?.removeEventListener('resize', updateMedia);
  });

  return (
    <Layout hasSider>
      <RouterPrompt openPrompt={state?.showPrompt} />
      {!isDesktop && (
        <span
          className={isActive ? 'active overlay-responsive' : 'overlay-disable'}
          onClick={handleOverlay}
        />
      )}

      <Sider
        theme="light"
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        className={isActive ? 'close' : ''}
        breakpoint={BREAKPOINTS?.mobile}
      >
        <Link id="logo" to={ROUTES?.MAIN}>
          <img
            src={collapsed ? '/collapsed-logo.svg' : '/logo-name.svg'}
            alt="logo"
            className="full-height"
          />
        </Link>
        <Sidebar />
      </Sider>
      <Layout className="site-layout">
        <AppHeader>
          <div className="header-wrapper">
            <div className="d-flex align-center">
              <Button
                type="text"
                className="p-0"
                onClick={isDesktop ? handleToggle : handleOverlay}
              >
                {collapsed ? <MenuCloseIcon /> : <MenuIcon />}
              </Button>
              {!isDesktop && (
                <img
                  src="/collapsed-logo.svg"
                  alt="logo"
                  className="responsive-logo"
                />
              )}
            </div>
            <div id="header-title" className="text-bold font-medium" />
            <UserProfile />
          </div>
        </AppHeader>
        <Content className="wrapper">
          <ContentRoutes />
        </Content>
        <footer>
          <div>battlebuddies © 2023</div>
          <div className="d-flex align-center gap-4">
            Crafted Mindfully at
            <a
              href="https://www.logicwind.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="logicwind-logo"
                src="https://uploads-ssl.webflow.com/6343ba64da79c936f0ca3e3e/64a2aa65eea345f87da8a207_logicwind.png"
              />
            </a>
          </div>
        </footer>
      </Layout>
    </Layout>
  );
};

export default App;
