import { Menu } from 'antd';
import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  DashboardIcon,
  GameIcon,
  LeaderIcon,
  SettingsIcon,
  UserIcon,
} from '../../../assets/svg';
import { MODULES, ROUTES } from '../../../common/constants';

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const menuItems = [
  getItem(MODULES?.DASHBOARD, ROUTES?.MAIN, <DashboardIcon />),
  getItem(MODULES?.USERS, ROUTES?.USERS, <UserIcon />),
  getItem('Games', 'sub1', <GameIcon />, [
    getItem(MODULES?.DRAFTS, ROUTES?.DRAFTS),
    getItem(MODULES?.PUBLISH, ROUTES?.PUBLISH),
  ]),
  getItem(MODULES?.LEADERBOARD, ROUTES?.LEADERBOARD, <LeaderIcon />),
  // kept for future use
  // getItem(MODULES?.WITHDRAWALS, ROUTES?.WITHDRAWALS, <WithdrawalsIcon />),
  getItem(MODULES?.CONFIGURATION, ROUTES?.CONFIGURATION, <SettingsIcon />),
];

function Sidebar({ location: { pathname }, history }) {
  const onMenuSelect = (e) => {
    history?.push(e?.key);
  };

  return (
    <div className="side-bar">
      <Menu
        theme="light"
        mode="inline"
        className="side-bar-text"
        inlineIndent={16}
        selectedKeys={[`/${pathname?.split('/')?.[1]}`]}
        defaultSelectedKeys={[ROUTES?.MAIN]}
        onSelect={onMenuSelect}
        items={menuItems}
      />
    </div>
  );
}

export default withRouter(Sidebar);
