import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  ReloadOutlined,
  StopOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Card, Dropdown, Modal, Space, Table, Tag } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { MODULES, ROUTES, defaultDateFormat } from '../../common/constants';
import SearchComponent from '../../components/SearchComponent';
import history from '../../historyData';
import { DELETE_USER, STATUS_UPDATE } from './graphql/Mutations';
import { USER_LIST } from './graphql/Queries';

export const getCurrent = (skip, limit) => skip / limit + 1;
const { confirm } = Modal;

export default function User() {
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState('');
  const [sortOn] = useState('name');
  const [sortBy] = useState('ASC');
  const [total, setTotal] = useState(0);
  const [usersData, setUsersData] = useState(null);

  const [getUserList, { loading }] = useLazyQuery(USER_LIST, {
    fetchPolicy: 'network-only',
    onCompleted({ adminUsers: userData }) {
      setTotal(userData?.count);
      setUsersData(userData?.users);
    },
  });

  const [deleteUser] = useMutation(DELETE_USER, {
    onCompleted: () => {
      getUserList({
        variables: {
          filter: { skip, limit, search },
          sort: {
            sortOn,
            sortBy,
          },
        },
      });
    },
  });

  const [userStatus] = useMutation(STATUS_UPDATE, {
    onCompleted: () => {
      getUserList({
        variables: {
          filter: { skip, limit, search },
          sort: {
            sortOn,
            sortBy,
          },
        },
      });
    },
  });

  useEffect(() => {
    getUserList({
      variables: {
        filter: { skip, limit, search },
        sort: {
          sortOn,
          sortBy,
        },
      },
    });
  }, [skip, limit, search, sortOn, sortBy]);

  const handleEdit = (record) => {
    history?.push(`${ROUTES?.USERS}/edit/${record?.id}`);
  };

  const handleUpdate = async (record) => {
    confirm({
      title: record?.isActive
        ? 'Are you sure you want to De-activate this user.'
        : 'Are you sure you want to Re-activate this user.',
      okText: record?.isActive ? 'Yes, De-activate' : 'Yes, Re-activate',
      cancelText: 'Cancel',
      cancelButtonProps: {
        type: 'primary',
        danger: true,
        className: 'modal-btn-cancel',
      },
      okButtonProps: {
        className: 'modal-btn-ok',
      },
      async onOk() {
        try {
          await userStatus({
            variables: {
              data: {
                id: record?.id,
                status: record?.isActive ? 'DEACTIVATED' : 'ACTIVE',
              },
            },
            fetchPolicy: 'network-only',
          });
        } catch (error) {
          return error;
        }
      },
    });
  };

  const handleDelete = async (record) => {
    confirm({
      title: 'Are you sure you want to delete this user',
      okText: 'Yes,Delete',
      cancelText: 'Cancel',
      cancelButtonProps: {
        type: 'primary',
        danger: true,
      },
      okButtonProps: {
        className: 'modal-btn-ok',
      },
      async onOk() {
        try {
          await deleteUser({
            variables: {
              where: { userId: record?.id },
              data: {
                status: 'DRAFT',
              },
            },
            fetchPolicy: 'network-only',
          });
        } catch (error) {
          return error;
        }
      },
    });
  };

  const items = [
    {
      key: '1',
      label: 'Edit',
      icon: <EditOutlined />,
      onClick: handleEdit,
    },
    {
      key: '2',
      label: 'Delete',
      icon: <DeleteOutlined />,
      onClick: handleDelete,
    },
    {
      key: '3',
      label: 'De-activate',
      icon: <StopOutlined />,
      onClick: handleUpdate,
    },
  ];

  const itemsEdit = [
    {
      key: '1',
      label: 'Edit',
      icon: <EditOutlined />,
      onClick: handleEdit,
    },
    {
      key: '2',
      label: 'Delete',
      icon: <DeleteOutlined />,
      onClick: handleDelete,
    },
    {
      key: '3',
      label: 'Re-activate',
      icon: <ReloadOutlined />,
      onClick: handleUpdate,
    },
  ];

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '150px',
      fixed: 'left',
    },
    {
      title: 'Display Name',
      dataIndex: 'name',
      key: 'name',
      width: '154px',
    },
    {
      title: 'Last Active',
      dataIndex: 'lastLogin',
      key: 'lastLogin',
      width: '140px',
      render: (createdAt) =>
        moment(createdAt)?.format(`${defaultDateFormat} hh:mm a`),
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      key: 'isActive',
      width: '140px',
      render: (text, record) => (
        <Tag color={record?.isActive ? 'green' : 'red'}>
          {record?.isActive ? 'Active' : 'De-active'}
        </Tag>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: '80px',
      render: (_, record) => (
        <Space size="middle">
          <Dropdown
            placement="bottomRight"
            autoFocus
            key={record?.id}
            menu={{
              items: (record?.isActive ? items : itemsEdit)?.map((item) => ({
                ...item,
                onClick: () => item?.onClick(record),
              })),
            }}
          >
            <EllipsisOutlined rotate={90} />
          </Dropdown>
        </Space>
      ),
    },
  ];

  const handleSearchChange = (text) => {
    setSearch(text);
    setSkip(0);
  };

  const handleTableChange = ({ current, pageSize }, _, _1, { action }) => {
    switch (action) {
      case 'paginate': {
        setSkip(current * pageSize - pageSize);
        setLimit(pageSize);
        break;
      }
      default:
        break;
    }
  };

  const headerClassSelectors = [
    'header',
    '.ant-card .ant-card-head',
    '.ant-table-thead',
    '.ant-table-wrapper .ant-table-pagination.ant-pagination',
    'footer',
  ]; // Replace with your class selectors
  const headerHeight = headerClassSelectors?.reduce((totalHeight, selector) => {
    // eslint-disable-next-line no-undef
    const headerElement = document?.querySelector(selector);
    if (headerElement) {
      return totalHeight + headerElement?.clientHeight;
    }
    return totalHeight;
  }, 0);
  return (
    <div>
      <Card
        title={`${total} ${MODULES?.USERS} records found`}
        extra={<SearchComponent getData={handleSearchChange} />}
        className="user-listing page-card height-vh-86"
      >
        <div>
          <Table
            tableLayout="fixed"
            columns={columns}
            dataSource={usersData}
            rowKey="id"
            onChange={handleTableChange}
            pagination={{
              position: ['bottomCenter'],
              total,
              current: getCurrent(skip, limit),
              pageSize: limit,
            }}
            scroll={{
              y: `calc(100vh - ${headerHeight + 100}px)`,
            }}
            loading={loading}
          />
        </div>
      </Card>
    </div>
  );
}
