import {
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
  PlusOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Card,
  Dropdown,
  Modal,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import { camelCase, startCase } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  LIMIT,
  MODULES,
  ROUTES,
  defaultDateFormat,
} from '../../../common/constants';
import SearchComponent from '../../../components/SearchComponent';
import history from '../../../historyData';
import DraftModal from './DraftModal';
import './draft.less';
import { DELETE_GAME, UPDATE_STATUS } from './graphql/Mutation';
import { ADMIN_GAMES, GAME_CATEGORY } from './graphql/Query';

const handleClick = () => {
  history?.push(`/drafts/upload`);
};

export const getCurrent = (skip, limit) => skip / limit + 1;

const { confirm } = Modal;

export default function Drafts() {
  const [adminGames, setAdminGames] = useState([]);
  const [search, setSearch] = useState('');
  const [categoryId, setCategoryId] = useState();
  const [type, setType] = useState(null);
  const [limit, setLimit] = useState(LIMIT);
  const [skip, setSkip] = useState(0);
  const [total, setTotal] = useState(0);
  const [modalState, setModalState] = useState({
    open: false,
    data: null,
    mode: 'DELETE',
    loading: true,
  });
  const initialGameFilter = {
    filter: {
      status: 'DRAFT',
      skip,
      limit,
      search,
      categoryId,
      type,
    },
    sort: {
      sortBy: 'DESC',
    },
  };

  const initialGenreFilter = {
    filter: {
      limit: LIMIT,
    },
    sort: {
      sortOn: 'name',
      sortBy: 'ASC',
    },
  };

  const [fetchAdminGames, { loading: AdminGamesLoading }] = useLazyQuery(
    ADMIN_GAMES,
    {
      fetchPolicy: 'network-only',
      onCompleted({ adminGames: draftData }) {
        setTotal(draftData?.count);
        setAdminGames(draftData?.data);
      },
    },
  );

  const [fetchGenre, { data: GameCategoryData }] = useLazyQuery(GAME_CATEGORY, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetchGenre({
      variables: initialGenreFilter,
    });
  }, []);

  useEffect(() => {
    fetchAdminGames({
      variables: initialGameFilter,
    });
  }, [skip, limit, search, categoryId, type]);

  const [DeleteGame] = useMutation(DELETE_GAME, {
    onCompleted: () => {
      fetchAdminGames({
        variables: initialGameFilter,
      });
    },
  });

  const [UpdateGameStatus] = useMutation(UPDATE_STATUS, {
    onCompleted: () => {
      fetchAdminGames({
        variables: initialGameFilter,
      });
    },
  });

  const handleSearchChange = (text) => {
    setSearch(text);
  };
  const handleEdit = (record) => {
    history?.push(`${ROUTES?.DRAFTS}/edit/${record?.id}`);
  };

  const handleDelete = (record) => {
    confirm({
      title: 'Delete game',
      content:
        'Are you sure you want to delete this game? This will permanently remove all the records related to this game.',
      okText: 'Yes, Delete',
      cancelButtonProps: {
        type: 'primary',
        danger: true,
        className: 'modal-btn-cancel',
      },
      okButtonProps: {
        className: 'modal-btn-ok',
      },
      onOk() {
        DeleteGame({
          variables: {
            where: {
              id: record?.id,
            },
          },
        });
      },
    });
  };

  const handlePublish = (record) => {
    confirm({
      title: 'Publish game',
      content:
        'Are you sure you want to publish this game? The game will be made live for the application users and enable users to play.',
      okText: 'Yes, Publish',
      cancelButtonProps: {
        type: 'primary',
        danger: true,
        className: 'modal-btn-cancel',
      },
      okButtonProps: {
        className: 'modal-btn-ok',
      },
      onOk() {
        UpdateGameStatus({
          variables: {
            where: {
              id: record?.id,
            },
            data: {
              status: 'PUBLISHED',
            },
          },
        });
      },
    });
  };

  const handleTableChange = (
    { current, pageSize },
    filterType,
    _,
    { action },
  ) => {
    switch (action) {
      case 'paginate': {
        setSkip(current * pageSize - pageSize);
        setLimit(pageSize);
        break;
      }
      case 'filter': {
        setType(filterType?.type?.[0]);
        break;
      }

      default:
        break;
    }
  };

  const completeItems = [
    {
      key: '1',
      label: 'Edit',
      icon: <EditOutlined />,
      onClick: handleEdit,
    },
    {
      key: '2',
      label: 'Delete',
      icon: <DeleteOutlined />,
      onClick: handleDelete,
    },
    {
      key: '3',
      label: 'Publish',
      icon: <UploadOutlined />,
      onClick: handlePublish,
    },
  ];

  const inCompleteItems = [
    {
      key: '4',
      label: 'Edit',
      icon: <EditOutlined />,
      onClick: handleEdit,
    },
    {
      key: '5',
      label: 'Delete',
      icon: <DeleteOutlined />,
      onClick: handleDelete,
    },
  ];
  const column = [
    {
      dataIndex: 'name',
      title: 'Game Name',
      key: 'name',
      width: '140px',
      fixed: 'left',
    },
    {
      dataIndex: 'updatedAt',
      title: 'Updated On',
      key: 'updatedAt',
      width: '140px',
      render: (_, record) =>
        moment(record?.updatedAt)?.format(defaultDateFormat),
    },
    {
      dataIndex: 'platform',
      title: 'Platform',
      key: 'platform',
      width: '140px',
      render: (_, res) => {
        switch (res?.platform) {
          case 'IOS':
            return 'iOS';
          default:
            return startCase(camelCase(res?.platform));
        }
      },
    },
    {
      dataIndex: 'mode',
      title: 'Game Mode',
      key: 'mode',
      width: '140px',
      render: (_, res) => startCase(camelCase(res?.mode)),
    },
    {
      dataIndex: 'type',
      title: 'Game Type',
      key: 'type',
      width: '140px',
      filters: [
        { value: 'SINGLE_PLAYER', text: 'Single Player' },
        { value: 'TURN_BASED_MULTIPLAYER', text: 'Turn based Multiplayer' },
      ],
      render: (_, res) => startCase(camelCase(res?.type)),
      filterMultiple: false,
    },
    {
      dataIndex: 'draftStatus',
      title: 'Status',
      key: 'draftStatus',
      width: '140px',
      render: (res) => (
        <Tag color={res === 'COMPLETE' ? 'green' : 'red'}>
          {res === 'COMPLETE' ? 'Complete' : 'Incomplete'}
        </Tag>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: '80px',
      render: (_, record) => (
        <Space size="middle">
          <Dropdown
            autoFocus
            key={record?.id}
            menu={{
              items: (record?.draftStatus !== 'INCOMPLETE'
                ? completeItems
                : inCompleteItems
              )?.map((item) => ({
                ...item,
                onClick: () => item?.onClick(record),
              })),
            }}
            placement="bottomRight"
          >
            <MoreOutlined />
          </Dropdown>
        </Space>
      ),
    },
  ];
  const handleCancel = () => {
    setModalState({
      open: false,
      mode: 'DELETE',
      loading: true,
    });
  };
  const headerClassSelectors = [
    'header',
    '.ant-card .ant-card-head',
    '.ant-table-thead',
    '.ant-table-wrapper .ant-table-pagination.ant-pagination',
    'footer',
  ]; // Replace with your class selectors
  const headerHeight = headerClassSelectors?.reduce((totalHeight, selector) => {
    // eslint-disable-next-line no-undef
    const headerElement = document?.querySelector(selector);
    if (headerElement) {
      return totalHeight + headerElement?.clientHeight;
    }
    return totalHeight;
  }, 0);
  return (
    <div>
      <DraftModal
        open={modalState?.open}
        mode={modalState?.mode}
        onClose={handleCancel}
        onOk={modalState?.mode === 'DELETE' ? handleDelete : handlePublish}
      />

      <Card
        title={<>Game ({MODULES?.DRAFTS})</>}
        className="card-body-wrapper page-card height-vh-86"
        extra={
          <div className="d-flex align-center gap-4">
            <Select
              placeholder="Select genre"
              allowClear
              className="full-width"
              onChange={(value) => {
                setCategoryId(value);
              }}
              options={GameCategoryData?.gameCategories?.data.map(
                ({ id, name }) => ({
                  value: id,
                  label: name,
                }),
              )}
            />
            <SearchComponent getData={handleSearchChange} />
            <Tooltip placement="top" title="Upload Game">
              <Button
                onClick={handleClick}
                type="primary"
                icon={<PlusOutlined />}
                className="btn-form-save flex-shrink-0"
              />
            </Tooltip>
          </div>
        }
      >
        <Table
          dataSource={adminGames}
          columns={column}
          rowKey="id"
          loading={AdminGamesLoading}
          className="contain-overflow"
          onChange={handleTableChange}
          scroll={{
            y: `calc(100vh - ${headerHeight + 100}px)`,
          }}
          pagination={{
            position: ['bottomCenter'],
            total,
            current: getCurrent(skip, limit),
            pageSize: limit,
          }}
        />
      </Card>
    </div>
  );
}
