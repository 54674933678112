import {
  EditOutlined,
  EllipsisOutlined,
  StopOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Card, Dropdown, Modal, Select, Table } from 'antd';
import { camelCase, startCase } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  MODULES,
  ROUTES,
  defaultDateFormat,
  leaderboardSort,
} from '../../../common/constants';
import SearchComponent from '../../../components/SearchComponent';
import history from '../../../historyData';
import { UPDATE_STATUS } from './graphql/Mutations';
import { GAME_GENRE, PUBLISHED_GAMES } from './graphql/Queries';
import './published.less';

const { confirm } = Modal;
export const getCurrent = (skip, limit) => skip / limit + 1;

export default function Published() {
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState('');
  const [personLoading, setPersonsLoading] = useState(false);
  const [categoryId, setCategoryId] = useState();
  const [status] = useState('PUBLISHED');
  const [sortOn, setSortOn] = useState();
  const [sortBy] = useState('DESC');
  const [publishGame, setPublishGame] = useState([]);
  const [total, setTotal] = useState(0);
  const [type, setType] = useState(null);

  const [getPublishedList, { loading: publishListingLoading }] = useLazyQuery(
    PUBLISHED_GAMES,
    {
      fetchPolicy: 'network-only',
      onCompleted: ({ adminGames: publishData }) => {
        setTotal(publishData?.count);
        setPublishGame(publishData?.data);
      },
      onError: () => {
        setPersonsLoading(true);
      },
    },
  );

  const [fetchGenre, { data: genreData }] = useLazyQuery(GAME_GENRE, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [updateGameStatus, { loading: updateStatusLoading }] = useMutation(
    UPDATE_STATUS,
    {
      onCompleted: () => {
        getPublishedList({
          variables: {
            filter: { skip, limit, search, categoryId, status, type },
            sort: {
              sortOn,
              sortBy,
            },
          },
        });
      },
      onError() {},
    },
  );

  useEffect(() => {
    fetchGenre({
      variables: {
        filter: {
          search: '',
          skip: 0,
          limit: 15,
          categoryId,
        },
        sort: {
          sortOn: 'key',
          sortBy: 'ASC',
        },
      },
    });
  }, []);

  useEffect(() => {
    getPublishedList({
      variables: {
        filter: { skip, limit, search, categoryId, status, type },
        sort: {
          sortOn,
          sortBy,
        },
      },
    });
  }, [skip, limit, search, sortOn, sortBy, type, categoryId, status]);

  const handleEdit = (record) => {
    // Navigate to the edit page with the record ID
    history?.push(`${ROUTES?.PUBLISH}/edit/${record?.id}`);
  };

  const handleUnpublish = async (record) => {
    confirm({
      title:
        'Are you sure you want to un-publish this game? The game will be not be visible on the platform from now onwards.',
      okText: 'Yes, Unpublish',
      cancelText: 'Cancel',
      cancelButtonProps: {
        type: 'primary',
        danger: true,
      },
      okButtonProps: {
        className: 'modal-btn-ok',
      },
      async onOk() {
        try {
          updateGameStatus({
            variables: {
              where: { id: record?.id },
              data: {
                status: 'DRAFT',
              },
            },
            fetchPolicy: 'network-only',
          });
        } catch (error) {
          return error;
        }
      },
    });
  };

  const items = [
    {
      key: '1',
      label: 'Edit',
      icon: <EditOutlined />,
      onClick: handleEdit,
    },
    {
      key: '2',
      label: 'Un-publish',
      icon: <StopOutlined />,
      onClick: handleUnpublish,
    },
  ];

  const columns = [
    {
      title: 'Game Name',
      dataIndex: 'name',
      key: 'name',
      width: '180px',
      fixed: 'left',
    },
    {
      title: 'Published On',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '180px',
      render: (createdAt) => moment(createdAt)?.format(defaultDateFormat),
    },
    {
      title: 'Platform',
      dataIndex: 'platform',
      key: 'platform',
      width: '180px',
      render: (_, res) => {
        switch (res?.platform) {
          case 'IOS':
            return 'iOS';
          default:
            return startCase(camelCase(res?.platform));
        }
      },
    },
    {
      title: 'Game Mode',
      dataIndex: 'mode',
      key: 'mode',
      width: '180px',
      render: (_, res) => startCase(camelCase(res?.mode)),
    },
    {
      title: 'Game Type',
      dataIndex: 'type',
      key: 'type',
      width: '180px',
      render: (_, res) => startCase(camelCase(res?.type)),

      filters: [
        { text: 'Single-Player', value: 'SINGLE_PLAYER' },
        { text: 'Turn-Based-Multiplayer', value: 'TURN_BASED_MULTIPLAYER' },
      ],

      onFilter: (value, record) => record?.type === value,
      filterMultiple: false,
    },

    {
      title: 'Action',
      key: 'action',
      width: '80px',
      render: (_, record) => (
        <Dropdown
          placement="bottomRight"
          menu={{
            items: items?.map((item) => ({
              ...item,
              onClick: () => item?.onClick(record),
            })),
          }}
        >
          <EllipsisOutlined rotate={90} />
        </Dropdown>
      ),
    },
  ];

  const handleSearchChange = (text) => {
    setSearch(text);
  };

  const handleTableChange = (
    { current, pageSize },
    filterType,
    _1,
    { action },
  ) => {
    switch (action) {
      case 'paginate': {
        setSkip(current * pageSize - pageSize);
        setLimit(pageSize);
        break;
      }
      case 'filter': {
        setType(filterType?.type?.[0]);
        setSkip(0);
        break;
      }
      default:
        break;
    }
  };
  const headerClassSelectors = [
    'header',
    '.ant-card .ant-card-head',
    '.ant-table-thead',
    '.ant-table-wrapper .ant-table-pagination.ant-pagination',
    'footer',
  ]; // Replace with your class selectors
  const headerHeight = headerClassSelectors?.reduce((totalHeight, selector) => {
    // eslint-disable-next-line no-undef
    const headerElement = document?.querySelector(selector);
    if (headerElement) {
      return totalHeight + headerElement?.clientHeight;
    }
    return totalHeight;
  }, 0);
  return (
    <div>
      <Card
        title={<>Games ({MODULES?.PUBLISH})</>}
        className="card-body-wrapper page-card height-vh-86"
        extra={
          <div className="d-flex align-center gap-4">
            <SearchComponent getData={handleSearchChange} />
            <Select
              allowClear
              onChange={(value) => {
                setCategoryId(value);
                setSkip(0);
              }}
              className="published-selection-100"
              placeholder="Game Genre"
              options={genreData?.gameCategories?.data?.map(
                ({ id: categoryid, name }) => ({
                  value: categoryid,
                  label: name,
                }),
              )}
            />
            <Select
              placeholder="Sort"
              className="published-selection-100"
              allowClear
              onChange={(value) => {
                setSortOn(value);
              }}
              options={leaderboardSort}
            />
          </div>
        }
      >
        <div>
          <Table
            columns={columns}
            dataSource={publishGame}
            rowKey="id"
            onChange={handleTableChange}
            className="contain-overflow"
            scroll={{
              y: `calc(100vh - ${headerHeight + 100}px)`,
            }}
            pagination={{
              position: ['bottomCenter'],
              total,
              current: getCurrent(skip, limit),
              pageSize: limit,
            }}
            loading={
              publishListingLoading || personLoading || updateStatusLoading
            }
          />
        </div>
      </Card>
    </div>
  );
}
