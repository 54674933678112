import { useMutation } from '@apollo/client';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';
import LoaderComponent from '../../components/LoaderComponent';
import { REFRESH_TOKEN } from './graphql/Mutations';

const RefreshToken = () => {
  const { initializeAuth, getRefreshToken } = useContext(AppContext);
  const history = useHistory();
  const { state } = history?.location;
  const refreshToken = getRefreshToken();

  const [refresh, { loading }] = useMutation(REFRESH_TOKEN, {
    fetchPolicy: 'network-only',

    onCompleted(res) {
      initializeAuth(res?.refreshToken?.accessToken);
      if (state?.from) {
        history?.replace(state?.from);
      } else {
        history?.goBack();
      }
    },
    onError: () => {
      history?.replace(ROUTES?.LOGOUT);
    },
  });

  useEffect(() => {
    refresh({
      variables: {
        data: {
          refreshToken,
        },
      },
    });
  }, []);

  return <LoaderComponent spinning={loading} />;
};

export default RefreshToken;
