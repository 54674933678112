import { useMutation } from '@apollo/client';
import { Button, Form, Input, Spin, Typography } from 'antd';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { LockIcon, LoginLogo, UserIcon } from '../../assets/svg';
import SmallLogo from '../../assets/svg/collapsed-logo.svg';
import HeroImg from '../../assets/svg/hero-section-logo.svg';
import { formValidatorRules } from '../../common/utils';
import './auth.less';
import { LOGIN } from './graphql/Mutations';

const { required, email } = formValidatorRules;
const { Title } = Typography;

const Login = () => {
  const [form] = Form?.useForm();
  const history = useHistory();
  const { initializeAuth } = useContext(AppContext);
  const [loginMutate, { loading: loginLoading }] = useMutation(LOGIN, {
    onError() {}, // Always write this method for error handling in all mutation.
  });

  function successCallback(accessToken, userData, refreshToken) {
    initializeAuth(accessToken, userData, refreshToken);
    history?.replace('/');
  }

  const onFinish = async (values) => {
    try {
      const formValues = {
        email: values?.email?.trim().toLowerCase(),
        password: values?.password?.trim(),
      };
      const response = await loginMutate({
        variables: { data: { ...formValues } },
      });

      if (response?.data) {
        const accessToken = response?.data?.adminLogin?.token;
        const userData = response?.data?.adminLogin?.data;
        const tokenRefresh = response?.data?.adminLogin?.refreshToken;

        if (successCallback) {
          successCallback(accessToken, userData, tokenRefresh);
        }
      } else {
        form?.setFieldsValue(values);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console?.error('error from login => ', error);
    }
  };

  return (
    <div className="fancy-login-block">
      <div className="fancy-login-wrapper">
        <div className="f-login-inner">
          <div className="auth-bg">
            <div className="login-wrap d-flex align-center justify-center">
              <Spin spinning={loginLoading} wrapperClassName="full-width">
                <div className="login-page-title">
                  <img src={SmallLogo} alt="battlebuddies logo" />
                  <Title className="mt-0" level={3}>
                    Sign In
                  </Title>
                  <p>Welcome to battlebuddies, Please enter your details</p>
                </div>
                <Form name="Login" onFinish={onFinish} size="large" form={form}>
                  <Form.Item
                    name="email"
                    rules={[
                      { required, message: 'Please enter email!' },
                      email,
                    ]}
                  >
                    <Input prefix={<UserIcon />} placeholder="Enter email" />
                  </Form.Item>

                  <Form.Item
                    name="password"
                    rules={[{ required, message: 'Please enter password!' }]}
                  >
                    <Input.Password
                      prefix={<LockIcon />}
                      placeholder="Enter password"
                    />
                  </Form.Item>

                  <Form.Item className="full-width">
                    <Button
                      type="primary"
                      className="full-width"
                      htmlType="submit"
                    >
                      Login
                    </Button>
                  </Form.Item>
                </Form>
              </Spin>
            </div>
          </div>
        </div>
        <div className="f-login-inner bg-inner">
          <div>
            <div className="login-logo">
              <LoginLogo />
            </div>
            <img src={HeroImg} alt="hero-img" />
            <Title>
              Online Cash Games on <span>Battlebuddies</span>
            </Title>
          </div>
        </div>
      </div>
      <div className="login-copyright">© battlebuddies 2023</div>
    </div>
  );
};

export default Login;
