import { useLazyQuery } from '@apollo/client';
import { Card, Select, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  LIMIT,
  MODULES,
  ROUTES,
  leaderboardSort,
} from '../../common/constants';
import SearchComponent from '../../components/SearchComponent';
import history from '../../historyData';
import { ADMIN_GAMES } from '../game/drafts/graphql/Query';

export const getCurrent = (skip, limit) => skip / limit + 1;

export default function Leaderboard() {
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(LIMIT);
  const [search, setSearch] = useState();
  const [type, setType] = useState(null);
  const [adminGames, setAdminGames] = useState([]);
  const [total, setTotal] = useState(0);
  const [sortOn, setSortOn] = useState('MOST_PLAYED_TO_RECENT_PLAYED');
  const initialGameFilter = {
    filter: {
      status: 'PUBLISHED',
      skip,
      limit,
      search,
      type,
    },
    sort: {
      sortOn,
      sortBy: 'DESC',
    },
  };
  const [fetchAdminGames, { loading: adminGamesLoading }] = useLazyQuery(
    ADMIN_GAMES,
    {
      fetchPolicy: 'network-only',
      onCompleted: ({ adminGames: leaderBoardData }) => {
        setTotal(leaderBoardData?.count);
        setAdminGames(leaderBoardData?.data);
      },
    },
  );
  useEffect(() => {
    fetchAdminGames({
      variables: initialGameFilter,
    });
  }, [search, type, skip, limit, sortOn]);
  const handleSearchChange = (text) => {
    setSearch(text);
  };
  const handleTableChange = (
    { current, pageSize },
    filterType,
    _,
    { action },
  ) => {
    switch (action) {
      case 'paginate': {
        setSkip(current * pageSize - pageSize);
        setLimit(pageSize);
        break;
      }
      case 'filter': {
        setType(filterType?.type?.[0]);
        break;
      }

      default:
        break;
    }
  };

  const handleRowClick = (record) => {
    const id = record?.id;
    const handleRow = () => {
      history?.push(`${ROUTES?.LEADERBOARD}/${id}`);
    };
    return {
      onClick: handleRow,
    };
  };

  const column = [
    {
      dataIndex: 'name',
      title: 'Game Name',
      key: 'name',
      width: '150px',
      fixed: 'left',
    },
    {
      dataIndex: 'rating',
      title: 'Average Rating',
      key: 'rating',
      width: '150px',
      render: (_, record) => parseFloat(record?.rating || 0)?.toFixed(1),
    },
    {
      dataIndex: 'totalMatchPlayed',
      title: 'Total Match Played',
      key: 'totalMatchPlayed',
      width: '250px',
      render: (_, res) => res?.totalMatchPlayed,
    },
    {
      dataIndex: 'netRevenue',
      title: 'Net Revenue',
      key: 'netRevenue',
      width: '150px',
      render: (_, res) => res?.netRevenue,
    },
  ];
  const headerClassSelectors = [
    'header',
    '.ant-card .ant-card-head',
    '.ant-table-thead',
    '.ant-table-wrapper .ant-table-pagination.ant-pagination',
    'footer',
  ]; // Replace with your class selectors
  const headerHeight = headerClassSelectors?.reduce((totalHeight, selector) => {
    // eslint-disable-next-line no-undef
    const headerElement = document?.querySelector(selector);
    if (headerElement) {
      return totalHeight + headerElement?.clientHeight;
    }
    return totalHeight;
  }, 0);
  return (
    <div>
      <Card
        title={MODULES?.LEADERBOARD}
        className="card-body-wrapper page-card"
        extra={
          <div className="d-flex gap-8">
            <SearchComponent getData={handleSearchChange} />
            <Select
              allowClear
              placeholder="Select sort on"
              className="full-width"
              onChange={(value) => {
                setSortOn(value);
              }}
              options={leaderboardSort}
            />
          </div>
        }
      >
        <Table
          tableLayout="fixed"
          onRow={handleRowClick}
          dataSource={adminGames}
          columns={column}
          rowKey="id"
          loading={adminGamesLoading}
          className="contain-overflow"
          onChange={handleTableChange}
          scroll={{
            y: `calc(100vh - ${headerHeight + 100}px)`,
          }}
          pagination={{
            position: ['bottomCenter'],
            total,
            current: getCurrent(skip, limit),
            pageSize: limit,
          }}
        />
      </Card>
    </div>
  );
}
