import { gql } from '@apollo/client';

export const UPDATE_USER = gql`
  mutation AdminUserUpdateProfile(
    $where: AdminUserWhereUniqueInput
    $data: AdminUserUpdateInput
  ) {
    adminUserUpdateProfile(where: $where, data: $data) {
      message
      user {
        id
        name
        phoneNumber
        countryCode
        referralCode
        profileImage {
          key
          url
        }
        depositAmount
        winingAmount
        level
        location
        lastLogin
        isActive
        isOnboardingCompleted
        createdAt
        updatedAt
        friendsCount
        gamePlayed
        gameWon
      }
    }
  }
`;

export const DELETE_USER = gql`
  mutation AdminUserDelete($where: AdminUserWhereUniqueInput!) {
    adminUserDelete(where: $where) {
      message
    }
  }
`;

export const STATUS_UPDATE = gql`
  mutation AdminUserUpdateStatus($data: AdminUserUpdateStatusInput!) {
    adminUserUpdateStatus(data: $data) {
      message
      user {
        isActive
      }
    }
  }
`;
