import { DownOutlined, LoginOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Space } from 'antd';
import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { ROUTES } from '../../../common/constants';

const UserProfile = () => {
  const { getCurrentUser } = useContext(AppContext);
  const { pathname } = useLocation();
  const { email = '', name = '' } = getCurrentUser() || {};
  const items = [
    {
      key: 'logout',
      label: <Link to={ROUTES?.LOGOUT}>Logout</Link>,
      icon: <LoginOutlined />,
    },
  ];

  return (
    <Dropdown
      menu={{ items, selectedKeys: [`${pathname?.split('/')?.[1]}`] }}
      trigger={['click']}
    >
      <Space className="pointer d-flex align-center gap-4">
        <Avatar
          alt="Avatar"
          size="large"
        >{`${email?.[0]?.toUpperCase()}`}</Avatar>
        {name}
        <DownOutlined />
      </Space>
    </Dropdown>
  );
};

export default UserProfile;
