import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Col,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Typography,
} from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { REGEX } from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import { PUBLISHED_EDIT_BASICDETAIL } from './graphql/Mutations';
import { GAME_GENRE, PUBLISHED_SINGLE_GAME } from './graphql/Queries';

const { required } = formValidatorRules;
const { TextArea } = Input;
const { Title } = Typography;

const BasicDetail = ({ formId = 'basic-detail-form' }) => {
  const { dispatch } = useContext(AppContext);
  const { id: currentId } = useParams();
  const [form] = Form?.useForm();
  const [basicLoading, setBasicLoading] = useState(false);
  const [details, setDetails] = useState(null);

  const [fetchgame, { loading: FetchGame }] = useLazyQuery(
    PUBLISHED_SINGLE_GAME,
    {
      fetchPolicy: 'network-only',
      onCompleted: ({ adminGame: game }) => {
        setDetails(game);
        setBasicLoading(false);
      },
    },
  );

  const handleShowPrompt = () => {
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: true,
    });
  };

  const initialValues = {
    ...details,
    categoryId: details?.category?.id,
  };

  const [UpdateGame, { loading: updateLoading }] = useMutation(
    PUBLISHED_EDIT_BASICDETAIL,
    {
      onCompleted: () => {
        setBasicLoading(false);
      },
    },
  );
  useEffect(() => {
    if (currentId) {
      fetchgame({
        variables: { where: { id: currentId } },
      });
    }
  }, [currentId]);

  const { data: GameCategoryData } = useQuery(GAME_GENRE, {
    variables: {
      filter: {
        limit: 20,
        search: '',
      },
      sort: {
        sortBy: 'ASC',
        sortOn: 'name',
      },
    },
  });

  const onFinish = async (values) => {
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: false,
    });
    setBasicLoading(true);
    if (currentId) {
      UpdateGame({
        variables: {
          data: values,
          where: { id: currentId },
        },
      });
    }
  };

  if ((FetchGame && !details) || updateLoading || basicLoading) {
    return <LoaderComponent />;
  }
  return (
    <>
      <Title level={5} className="mt-0 mb-24">
        Basic Detail
      </Title>
      <Form
        layout="vertical"
        onFinish={onFinish}
        onValuesChange={handleShowPrompt}
        initialValues={details ? initialValues : null}
        form={form}
        id={formId}
      >
        <Row gutter={16}>
          <Col xs={24} lg={8}>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ ...required, message: 'Please enter game name!' }]}
            >
              <Input size="large" allowClear placeholder="Enter game name" />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item
              name="categoryId"
              label="Genre"
              rules={[{ ...required, message: 'Please select genre!' }]}
            >
              <Select
                placeholder="Select genre"
                options={GameCategoryData?.gameCategories?.data?.map(
                  ({ id: categoryId, name }) => ({
                    value: categoryId,
                    label: name,
                  }),
                )}
                size="large"
                allowClear
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item
              name="version"
              label="Version Number"
              rules={[
                {
                  ...required,
                  message: 'Please enter game version number!',
                },
              ]}
            >
              <InputNumber
                size="large"
                placeholder="Please enter game version number"
                className="input-full-width"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} lg={12}>
            <Form.Item name="description" label="Game description">
              <TextArea
                size="small"
                placeholder="Enter few words about your game"
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              name="link"
              label="Game link"
              rules={[
                { ...required, message: 'Please enter game link!' },
                {
                  pattern: REGEX?.WEB_URL,
                  message: 'Please enter valid game link',
                },
              ]}
            >
              <Input size="large" allowClear placeholder="Enter game link" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xs={24} lg={12}>
            <Form.Item
              name="platform"
              label="Platform"
              rules={[{ ...required, message: 'Please select platform!' }]}
            >
              <Radio.Group>
                <Row gutter={16}>
                  <Col>
                    <Radio value="ANDROID">Android</Radio>
                  </Col>
                  <Col>
                    <Radio value="IOS">iOS</Radio>
                  </Col>
                  <Col>
                    <Radio value="BOTH">Both</Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              name="orientation"
              label="Orientation"
              rules={[{ ...required, message: 'Please select orientation!' }]}
            >
              <Radio.Group>
                <Row gutter={16}>
                  <Col>
                    <Radio value="PORTRAIT">Portrait</Radio>
                  </Col>
                  <Col>
                    <Radio value="LANDSCAPE">Landscape</Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} lg={12}>
            <Form.Item
              name="mode"
              label="Mode"
              rules={[{ ...required, message: 'Please select game mode!' }]}
            >
              <Radio.Group>
                <Row gutter={16}>
                  <Col>
                    <Radio value="ONE_VS_ONE">One vs One</Radio>
                  </Col>
                  <Col>
                    <Radio value="ONE_VS_MANY">One vs Many</Radio>
                  </Col>
                  <Col>
                    <Radio value="BOTH">Both</Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              name="type"
              label="Type"
              rules={[{ ...required, message: 'Please select game type!' }]}
            >
              <Radio.Group>
                <Row gutter={16}>
                  <Col>
                    <Radio value="SINGLE_PLAYER">Single player</Radio>
                  </Col>
                  <Col>
                    <Radio value="TURN_BASED_MULTIPLAYER">
                      Turn Based Multiplayer
                    </Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default BasicDetail;
