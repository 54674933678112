import {
  BarChartOutlined,
  CustomerServiceOutlined,
  IdcardOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Button, Card, Col, Row, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import CommonBreadcrumb from '../../../BreadCrumb';
import { ROUTES } from '../../../common/constants';
import LoaderComponent from '../../../components/LoaderComponent';
import history from '../../../historyData';
import BasicDetails from './BasicDetail';
import Configuration from './Configuration';
import GameAsset from './GameAsset';
import Statistics from './Statistics';
import './draft.less';
import { ADMIN_SINGLE_GAME } from './graphql/Query';

const formIds = {
  1: 'basic-detail-form',
  2: 'game-asset-form',
  3: 'config-form',
};
function UploadForms() {
  const [activeKey, setActiveKey] = useState(1);
  const onTab1Change = (key) => {
    setActiveKey(key);
  };
  const [id, setId] = useState('');
  const { id: editId } = useParams();
  const [loading, setLoading] = useState(false);
  const [gameTitle, setGameTitle] = useState('');
  const { pathname } = useLocation();
  const isEdit = pathname?.split('/')?.[2];
  const [singleGame, { data, loading: gameTitleLoading }] = useLazyQuery(
    ADMIN_SINGLE_GAME,
    {
      fetchPolicy: 'network-only',
      onCompleted({ adminGame: gameData }) {
        setGameTitle(gameData?.name);
      },
    },
  );
  useEffect(() => {
    if (editId) {
      singleGame({
        variables: {
          where: {
            id: editId,
          },
        },
      });
    }
  }, [editId]);

  const crumb = [
    {
      name: 'games',
    },
    {
      name: !editId ? 'Upload Game' : `${gameTitle}`,
    },
  ];
  const tabList = [
    {
      key: 1,
      tab: (
        <>
          <IdcardOutlined />
          Basic Details
        </>
      ),
    },
    {
      key: 2,
      disabled: !editId && !id,
      tab: (
        <>
          <CustomerServiceOutlined />
          Game Assets
        </>
      ),
    },
    {
      key: 3,
      disabled: !editId && !id,
      tab: (
        <>
          <SettingOutlined />
          Configuration
        </>
      ),
    },
    editId && data?.adminGame?.asset && data?.adminGame?.config
      ? {
          key: 4,
          tab: (
            <>
              <BarChartOutlined />
              Statistics
            </>
          ),
        }
      : {},
  ];
  const contentList = {
    1: (
      <LoaderComponent spinning={loading}>
        <BasicDetails
          formId={formIds?.[1]}
          onDone={(gameId) => {
            setId(gameId);
            setActiveKey(2);
            history?.push(`${ROUTES?.DRAFTS}${ROUTES?.UPLOAD}/${gameId}`);
          }}
          setLoading={setLoading}
          editId={editId}
        />
      </LoaderComponent>
    ),
    2: (
      <LoaderComponent spinning={loading}>
        <GameAsset
          id={id}
          formId={formIds?.[2]}
          onDone={() => {
            setActiveKey(3);
          }}
          setLoading={setLoading}
          editId={editId}
        />
      </LoaderComponent>
    ),
    3: (
      <LoaderComponent spinning={loading}>
        <Configuration
          id={id}
          formId={formIds?.[3]}
          setLoading={setLoading}
          editId={editId}
        />
      </LoaderComponent>
    ),

    4: <Statistics editId={editId} />,
  };

  const handleBack = () => {
    history?.push(ROUTES?.DRAFTS);
  };
  // kept for future use
  // const items = [
  //   {
  //     key: 1,
  //     label: `Basic Detail`,
  //     children: (
  //       <LoaderComponent spinning={loading}>
  //         <BasicDetails
  //           formId={formIds?.[1]}
  //           onDone={(gameId) => {
  //             setId(gameId);
  //             setActiveKey(2);
  //           }}
  //           setLoading={setLoading}
  //           editId={editId}
  //         />
  //       </LoaderComponent>
  //     ),
  //   },
  //   {
  //     key: 2,
  //     label: `Game Assets`,
  //     disabled: !editId && !id,
  //     children: (
  //       <LoaderComponent spinning={loading}>
  //         <GameAsset
  //           id={id}
  //           formId={formIds?.[2]}
  //           onDone={() => {
  //             setActiveKey(3);
  //           }}
  //           setLoading={setLoading}
  //           editId={editId}
  //         />
  //       </LoaderComponent>
  //     ),
  //   },
  //   {
  //     key: 3,
  //     label: `Configuration`,
  //     disabled: !editId && !id,
  //     children: (
  //       <LoaderComponent spinning={loading}>
  //         <Configuration
  //           id={id}
  //           formId={formIds?.[3]}
  //           setLoading={setLoading}
  //           editId={editId}
  //         />
  //       </LoaderComponent>
  //     ),
  //   },
  //   editId &&
  //     data?.adminGame?.asset &&
  //     data?.adminGame?.config && {
  //       key: 4,
  //       label: `Statistics`,
  //       children: <Statistics editId={editId} />,
  //     },
  // ];

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
          <Card
            className="side-bar-card"
            title="Game"
            tabList={tabList}
            activeTabKey={activeKey}
            onTabChange={onTab1Change}
            tabProps={{
              tabPosition: 'left',
            }}
          />
        </Col>
        <Col xs={24} sm={16} md={16} lg={16} xl={16}>
          <Card
            className="card-out"
            title={<CommonBreadcrumb crumbs={crumb} />}
            actions={
              activeKey !== 4 && [
                <div key="actionButton" className="text-center">
                  <Space>
                    <Row gutter={8}>
                      <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Button
                          key="cancel"
                          htmlType="submit"
                          onClick={handleBack}
                          className="btn-form-cancel"
                          disabled={loading || gameTitleLoading}
                        >
                          Cancel
                        </Button>
                      </Col>
                      <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Button
                          type="primary"
                          key="edit"
                          htmlType="submit"
                          form={formIds?.[activeKey]}
                          className="btn-form-save"
                          loading={loading || gameTitleLoading}
                        >
                          {editId && isEdit === 'edit'
                            ? `${
                                activeKey === 3
                                  ? 'Publish'
                                  : 'Save Game As Draft'
                              }`
                            : `${
                                activeKey === 3
                                  ? 'Save Game As Draft'
                                  : 'Save & Next'
                              }`}
                        </Button>
                      </Col>
                    </Row>
                  </Space>
                </div>,
              ]
            }
          >
            {contentList?.[activeKey]}
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default UploadForms;
