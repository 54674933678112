import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Col,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GENRE_LIMIT, REGEX } from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import './draft.less';
import { CREATE_GAME, UPDATE_GAME } from './graphql/Mutation';
import { ADMIN_SINGLE_GAME, GAME_CATEGORY } from './graphql/Query';

const { required } = formValidatorRules;
const { TextArea } = Input;
const { Title } = Typography;

const BasicDetail = ({ onDone, formId, setLoading, editId }) => {
  const [form] = Form?.useForm();
  const [basicLoading, setBasicLoading] = useState(true);
  const [details, setDetails] = useState(null);
  const { id } = useParams();

  const [createGame, { loading: createGameLoading }] = useMutation(
    CREATE_GAME,
    {
      onCompleted(res) {
        if (res?.createGame?.data?.id) {
          onDone(res?.createGame?.data?.id, setLoading(false));
        }
      },
      onError() {
        setLoading(false);
      },
    },
  );
  const [fetchGame, { loading: fetchSingleGameLoading }] = useLazyQuery(
    ADMIN_SINGLE_GAME,
    {
      fetchPolicy: 'network-only',
      onCompleted: ({ adminGame: game }) => {
        setDetails(game);
      },
      onError() {
        setLoading(false);
      },
    },
  );

  const initialValues =
    !editId || !id
      ? {
          name: '',
          categoryId: null,
          link: '',
          description: '',
          version: '',
          platform: 'IOS',
          orientation: 'PORTRAIT',
          mode: 'ONE_VS_ONE',
          type: 'SINGLE_PLAYER',
        }
      : {
          ...details,
          categoryId: details?.category?.id,
        };

  const [UpdateGame, { loading: updateLoading }] = useMutation(UPDATE_GAME, {
    onCompleted: () => {
      setLoading(false);
    },
    onError() {
      setLoading(false);
    },
  });
  useEffect(() => {
    if (editId || id) {
      fetchGame({
        variables: { where: { id: editId || id } },
      });
    }
  }, [editId, id]);

  const { data: GameCategoryData } = useQuery(GAME_CATEGORY, {
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        limit: GENRE_LIMIT,
      },
      sort: {
        sortBy: 'ASC',
        sortOn: 'name',
      },
    },
    onCompleted: () => {
      setBasicLoading(false);
    },
  });

  const onFinish = async (values) => {
    setLoading(true);
    if (editId) {
      UpdateGame({
        variables: {
          data: values,
          where: { id: editId },
        },
      });
    } else {
      createGame({
        variables: {
          data: values,
        },
      });
    }
  };

  if (
    (fetchSingleGameLoading && !details) ||
    basicLoading ||
    updateLoading ||
    createGameLoading
  ) {
    return <LoaderComponent />;
  }
  return (
    <>
      <Title level={5} className="mt-0 mb-24">
        Basic Detail
      </Title>
      <Form
        id={formId}
        layout="vertical"
        onFinish={onFinish}
        initialValues={initialValues}
        form={form}
      >
        <Row gutter={16}>
          <Col xs={24} lg={8}>
            <Form.Item
              name="name"
              label="Game name"
              rules={[{ ...required, message: 'Please enter game name!' }]}
            >
              <Input size="large" placeholder="Enter game name" allowClear />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item
              name="categoryId"
              label="Genre"
              rules={[{ ...required, message: 'Please select genre!' }]}
            >
              <Select
                placeholder="Select genre"
                options={GameCategoryData?.gameCategories?.data?.map(
                  ({ id: categoryId, name }) => ({
                    value: categoryId,
                    label: name,
                  }),
                )}
                size="large"
                allowClear
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item
              name="version"
              label="Game version number"
              rules={[
                {
                  ...required,
                  message: 'Please enter game version number!',
                },
              ]}
            >
              <InputNumber
                size="large"
                min={0}
                placeholder="Enter game version number"
                className="input-full-width"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} lg={12}>
            <Form.Item name="description" label="Game description">
              <TextArea
                size="small"
                placeholder="Enter few words about your game"
              />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item
              name="link"
              label="Game link"
              rules={[
                {
                  ...required,
                  message: 'Please enter game link!',
                },
                {
                  pattern: REGEX?.WEB_URL,
                  message: 'Please enter valid game link',
                },
              ]}
            >
              <Input size="large" placeholder="Enter game link" allowClear />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xs={24} lg={12}>
            <Form.Item
              name="platform"
              label="Platform"
              rules={[{ ...required, message: 'Please select platform!' }]}
            >
              <Radio.Group>
                <Row gutter={16}>
                  <Col>
                    <Radio value="ANDROID">Android</Radio>
                  </Col>
                  <Col>
                    <Radio value="IOS">iOS</Radio>
                  </Col>
                  <Col>
                    <Radio value="BOTH">Both</Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              name="orientation"
              label="Orientation"
              rules={[{ ...required, message: 'Please select orientation!' }]}
            >
              <Radio.Group>
                <Row gutter={16}>
                  <Col>
                    <Radio value="PORTRAIT">Portrait</Radio>
                  </Col>
                  <Col>
                    <Radio value="LANDSCAPE">Landscape</Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} lg={12}>
            <Form.Item
              name="mode"
              label="Game Mode"
              rules={[{ ...required, message: 'Please select game mode!' }]}
            >
              <Radio.Group>
                <Row gutter={16}>
                  <Col>
                    <Radio value="ONE_VS_ONE">One vs One</Radio>
                  </Col>
                  <Col>
                    <Radio value="ONE_VS_MANY">One vs Many</Radio>
                  </Col>
                  <Col>
                    <Radio value="BOTH">Both</Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              name="type"
              label="Game Type"
              rules={[{ ...required, message: 'Please select game type!' }]}
            >
              <Radio.Group>
                <Row gutter={16}>
                  <Col>
                    <Radio value="SINGLE_PLAYER">Single player</Radio>
                  </Col>
                  <Col>
                    <Radio value="TURN_BASED_MULTIPLAYER">
                      Turn Based Multiplayer
                    </Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default BasicDetail;
