import { useLazyQuery } from '@apollo/client';
import { Card, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { MODULES } from '../../common/constants';
import LoaderComponent from '../../components/LoaderComponent';
import ConfigForm from './ConfigForm';
import CONFIG from './graphql/Query';

const { Title } = Typography;

export default function Configuration() {
  const [configs, setConfigs] = useState([]);

  const [configMutation, { loading: configLoading }] = useLazyQuery(CONFIG, {
    fetchPolicy: 'network-only',
    onCompleted: ({ configurations }) => {
      if (configurations?.data) {
        setConfigs(configurations?.data);
      }
    },
  });

  useEffect(() => {
    configMutation({
      variables: {
        sort: {
          sortBy: 'ASC',
          sortOn: 'key',
        },
      },
    });
  }, []);

  return (
    <div>
      <Card className="card-body-wrapper" title={MODULES?.CONFIGURATION}>
        <Title level={4} className="mt-0">
          Game Earning
        </Title>
        {configLoading ? (
          <LoaderComponent />
        ) : (
          configs?.map((configData) => (
            <ConfigForm
              key={configData?.id}
              keyField={configData?.key}
              value={configData?.value}
              name={configData?.name}
              configMutation={configMutation}
            />
          ))
        )}
      </Card>
    </div>
  );
}
