import { gql } from '@apollo/client';

export const CREATE_GAME = gql`
  mutation CreateGame($data: CreateGameInput!) {
    createGame(data: $data) {
      data {
        mode
        category {
          key
        }
        link
        name
        platform
        orientation
        status
        type
        version
        description
        id
      }
      message
    }
  }
`;

export const GAME_ASSET = gql`
  mutation UpsertGameAsset(
    $data: GameAssetInput!
    $where: GameWhereUniqueInput!
  ) {
    upsertGameAsset(data: $data, where: $where) {
      data {
        id
        createdAt
        backgroundImage {
          key
          url
        }
        icon {
          key
          url
        }
        logotype {
          key
          url
        }
        screenshots {
          key
          url
        }
        youtubeUrl
      }
      message
    }
  }
`;

export const GAME_CONFIGURATION = gql`
  mutation UpsertGameConfiguration(
    $data: GameConfigurationInput!
    $where: GameWhereUniqueInput
  ) {
    upsertGameConfiguration(data: $data, where: $where) {
      data {
        id
        gameTime
        findOpponentsTime
        live
        isOpponentsRequired
        winingAmountMargin
        playFree
        coinOption
        createdAt
        updatedAt
      }
      message
    }
  }
`;

export const DELETE_GAME = gql`
  mutation DeleteGame($where: GameWhereUniqueInput!) {
    deleteGame(where: $where) {
      message
    }
  }
`;

export const UPDATE_STATUS = gql`
  mutation UpdateGameStatus(
    $where: GameWhereUniqueInput
    $data: UpdateGameStatusInput
  ) {
    updateGameStatus(where: $where, data: $data) {
      data {
        status
        id
        name
      }
      message
    }
  }
`;

export const UPDATE_GAME = gql`
  mutation UpdateGame($data: UpdateGameInput!, $where: GameWhereUniqueInput!) {
    updateGame(data: $data, where: $where) {
      data {
        id
        name
        version
        description
        platform
        orientation
        mode
        type
        status
        updatedAt
        category {
          id
        }
      }
      message
    }
  }
`;
