import { useLazyQuery } from '@apollo/client';
import { Card, Col, DatePicker, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import CommonCharts from '../../components/CommonCharts';
import EmptyStatePage from '../../components/EmptyStatePage';
import LoaderComponent from '../../components/LoaderComponent';
import './dashboard.less';
import USER_STATISTICS, { GAME_NET_REVENUES } from './graphql/Queries';

const { RangePicker } = DatePicker;

const netRevenueOptions = {
  hAxis: {
    title: 'Months',
  },
  vAxis: {
    title: 'Net Revenue (in Rupees)',
  },
  colors: ['green'],
  legend: 'none',
};

const userCountGraphOptions = {
  hAxis: {
    title: 'Months',
  },
  vAxis: {
    title: 'User Count',
  },
  colors: ['Purple'],
  legend: 'none',
};

function DashboardCharts() {
  const [gameNetRevenue, setGameNetRevenue] = useState([]);
  const [userCountData, setUserCountData] = useState([]);
  const [revenueChartDateFrom, setRevenueChartDateFrom] = useState(null);
  const [revenueChartDateTo, setRevenueChartDateTo] = useState(null);
  const [userStatsDateFrom, setUserStatsDateFrom] = useState(null);
  const [userStatsDateTo, setUserStatsDateTo] = useState(null);

  const [gameRevenueChart, { loading: netRevenueLoading }] = useLazyQuery(
    GAME_NET_REVENUES,
    {
      fetchPolicy: 'network-only',
      onCompleted({ netRevenue: gameNetRevenueData }) {
        setGameNetRevenue(gameNetRevenueData?.netRevenue);
      },
      onError() {},
    },
  );
  const [userCount, { loading: userCountLoading }] = useLazyQuery(
    USER_STATISTICS,
    {
      fetchPolicy: 'network-only',
      onCompleted({ userStatistics: userStatsData }) {
        setUserCountData(userStatsData?.usersCount);
      },
      onError() {},
    },
  );
  useEffect(() => {
    gameRevenueChart({
      variables: {
        where: {
          id: '',
        },
        filter: {
          date: {
            from: revenueChartDateFrom,
            to: revenueChartDateTo,
          },
        },
      },
    });
    userCount({
      variables: {
        filter: {
          date: {
            from: userStatsDateFrom,
            to: userStatsDateTo,
          },
        },
      },
    });
  }, [
    revenueChartDateFrom,
    revenueChartDateTo,
    userStatsDateFrom,
    userStatsDateTo,
  ]);

  const getRevenueMonth = [...gameNetRevenue?.map((e) => e?.month)];
  const setRevenueMonth = getRevenueMonth?.map((e) => new Date(e));

  const getUserCountMonth = [...userCountData?.map((e) => e?.month)];
  const setUserCountMonth = getUserCountMonth?.map((e) => new Date(e));

  const monthName = setRevenueMonth
    ?.toLocaleString('default', { month: 'short' })
    ?.toLocaleUpperCase()
    ?.split(',');

  const userCountMonthString = setUserCountMonth
    ?.toLocaleString('default', { month: 'short' })
    ?.toLocaleUpperCase()
    ?.split(',');

  const netRevenueByDate = [...gameNetRevenue?.map((e) => e?.amount)];
  const userCountDataByDate = [...userCountData?.map((e) => e?.count)];

  const netRevenueGraphData = [];
  const userGraphData = [];

  for (
    let i = 0;
    i < Math?.min(monthName?.length, netRevenueByDate?.length);
    // eslint-disable-next-line no-plusplus
    i++
  ) {
    netRevenueGraphData?.push([monthName?.[i], netRevenueByDate?.[i]]);
  }
  for (
    let i = 0;
    i < Math?.min(userCountMonthString?.length, userCountDataByDate?.length);
    // eslint-disable-next-line no-plusplus
    i++
  ) {
    userGraphData?.push([userCountMonthString?.[i], userCountDataByDate?.[i]]);
  }
  const netRevenueData = [['Months', 'Revenue'], ...netRevenueGraphData];
  const userCountGraphData = [['Months', 'User count'], ...userGraphData];

  const handleRevenueChartFilter = (dateRange) => {
    setRevenueChartDateFrom(dateRange?.[0] || null);
    setRevenueChartDateTo(dateRange?.[1] || null);
  };
  const handleUserCountFilter = (dateRange) => {
    setUserStatsDateFrom(dateRange?.[0] || null);
    setUserStatsDateTo(dateRange?.[1] || null);
  };

  return (
    <div>
      <Row gutter={24}>
        <Col sm={24} md={24} xl={12}>
          <Card
            className="mb-24 responsive-charts"
            title="Net Revenue"
            extra={<RangePicker onChange={handleRevenueChartFilter} />}
          >
            <LoaderComponent spinning={netRevenueLoading}>
              {netRevenueGraphData?.length > 0 ? (
                <CommonCharts
                  chartType="LineChart"
                  width="100%"
                  height="400px"
                  data={netRevenueData}
                  options={netRevenueOptions}
                />
              ) : (
                <EmptyStatePage />
              )}
            </LoaderComponent>
          </Card>
        </Col>
        <Col sm={24} md={24} xl={12}>
          <Card
            className="mb-24 responsive-charts"
            title="User Count"
            extra={<RangePicker onChange={handleUserCountFilter} />}
          >
            <Row>
              <Col span={24}>
                <LoaderComponent spinning={userCountLoading}>
                  {userGraphData?.length > 0 ? (
                    <CommonCharts
                      chartType="LineChart"
                      width="100%"
                      height="400px"
                      data={userCountGraphData}
                      options={userCountGraphOptions}
                    />
                  ) : (
                    <EmptyStatePage />
                  )}
                </LoaderComponent>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default DashboardCharts;
