import { Modal } from 'antd';
import React from 'react';

export default function DraftModal({ open, mode, onOk, onClose }) {
  const handleModalClose = () => {
    onClose();
  };

  return (
    <div>
      <Modal
        title={mode === 'DELETE' ? 'Delete' : 'Publish'}
        onOk={onOk}
        okText={mode === 'DELETE' ? 'Yes, Delete' : 'Yes, publish'}
        open={open}
        onCancel={handleModalClose}
      >
        {mode === 'DELETE'
          ? 'Are you sure you want to delete this game? This will permanently remove all the records related to this game.'
          : 'Are you sure you want to publish this game? The game will be made live for the application users and enable users to play.'}
      </Modal>
    </div>
  );
}
