import { Typography } from 'antd';
import React from 'react';
import { MODULES } from '../../common/constants';
import DashboardCharts from './DashboardCharts';
import DashboardLeaderBoard from './DashboardLeaderboard';
import DashboardStatistics from './DashboardStatistics';

const { Title } = Typography;
const Dashboard = () => (
  <>
    <Title level={4} className="mt-0">
      {MODULES?.DASHBOARD}
    </Title>
    <DashboardStatistics />
    <DashboardCharts />
    <DashboardLeaderBoard />
    {/*
      kept for future use
    <DashboardHighestWithdrawals /> */}
  </>
);

export default Dashboard;
