import { useLazyQuery } from '@apollo/client';
import { Button, Card, Select, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { ROUTES } from '../../common/constants';
import LoaderComponent from '../../components/LoaderComponent';
import history from '../../historyData';
import { ADMIN_GAMES, ADMIN_LEADERBOARD } from '../game/drafts/graphql/Query';

const initialGameFilter = {
  filter: {
    status: 'PUBLISHED',
    limit: null,
  },
  sort: {
    sortBy: 'DESC',
  },
};

function DashboardLeaderBoard() {
  const [adminGamesData, setAdminGamesData] = useState([]);
  const [topPlayersData, setTopPlayersData] = useState([]);
  const [leaderByGame, setLeaderByGame] = useState('');

  const [adminGames, { loading: adminGamesLoading }] = useLazyQuery(
    ADMIN_GAMES,
    {
      fetchPolicy: 'network-only',
      onCompleted({ adminGames: gameData }) {
        setAdminGamesData(gameData?.data);
      },
    },
  );

  const [getTopPlayers, { loading: topPlayersLoading }] = useLazyQuery(
    ADMIN_LEADERBOARD,
    {
      fetchPolicy: 'network-only',
      onCompleted({ adminLeaderBoard: leadersData }) {
        setTopPlayersData(leadersData?.data);
      },
      onError() {},
    },
  );

  useEffect(() => {
    adminGames({
      variables: initialGameFilter,
    });
    getTopPlayers({
      variables: {
        where: {
          gameId: leaderByGame,
        },
        filter: {
          date: 'ALL_TIME',
          limit: 5,
        },
        sort: {
          sortBy: 'DESC',
          sortOn: 'highestScore',
        },
      },
    });
  }, [leaderByGame]);

  const columns = [
    {
      dataIndex: 'name',
      title: 'Rank',
      key: 'name',
      fixed: 'left',
      render: (_, record, index) => index + 1,
    },
    {
      dataIndex: 'name',
      title: 'Display name',
      key: 'name',
      render: (_, record) => record?.user?.name,
    },
    {
      dataIndex: 'email',
      title: 'Email',
      key: 'email',
      render: (_, record) => record?.user?.email,
    },

    {
      dataIndex: 'highestScore',
      title: 'Highest Score',
      key: 'highestScore',
      render: (_, record) => record?.highestScore,
    },
  ];

  const handleLeadersByGame = (selectedId) => {
    setLeaderByGame(selectedId);
  };

  return (
    <div className="mb-24 dashboard-table-card">
      <LoaderComponent spinning={adminGamesLoading || topPlayersLoading}>
        <Card
          title="Leader Board"
          extra={
            <div className="d-flex align-center gap-8">
              <Select
                className="full-width"
                placeholder="Select game"
                options={[
                  { label: 'All Game', value: '' },
                  ...adminGamesData?.map(({ id, name }) => ({
                    label: name,
                    value: id,
                  })),
                ]}
                onChange={handleLeadersByGame}
                defaultValue="All Games"
              />
              <Button
                type="primary"
                onClick={() => history?.push(ROUTES?.LEADERBOARD)}
              >
                View All
              </Button>
            </div>
          }
        >
          <div className="responsive-table">
            <Table
              key="leaderBoard"
              columns={columns}
              dataSource={topPlayersData}
              pagination={false}
            />
          </div>
        </Card>
      </LoaderComponent>
    </div>
  );
}

export default DashboardLeaderBoard;
