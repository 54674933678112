import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import Published from './Published';
import UserEdit from './UserEdit';

function PublishWrapper() {
  return (
    <Switch>
      <Route exact path={ROUTES?.PUBLISH} component={Published} />
      <Route exact path={`${ROUTES?.PUBLISH}/edit/:id`} component={UserEdit} />
    </Switch>
  );
}

export default PublishWrapper;
