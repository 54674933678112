import { useLazyQuery } from '@apollo/client';
import { Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import LoaderComponent from '../../../components/LoaderComponent';

import {
  ChartIcon,
  RevenueIcon,
  StarIcon,
  UserIcon,
  WithdrawalsIcon,
} from '../../../assets/svg';
import { currencyFormate, formatNumber } from '../../../common/utils';
import { GAME_STATISTICS } from './graphql/Query';

const { Title } = Typography;

function Statistics({ editId = null }) {
  const [gameStatsData, setGameStatsData] = useState(null);

  const [AdminGameStatistic, { loading: gameStatics }] = useLazyQuery(
    GAME_STATISTICS,
    {
      fetchPolicy: 'network-only',
      onCompleted: ({ adminGameStatistic: gameStats }) => {
        setGameStatsData(gameStats);
      },
    },
  );

  useEffect(() => {
    AdminGameStatistic({
      variables: {
        where: {
          id: editId,
        },
      },
    });
  }, []);

  const uniquePlayer = formatNumber?.format(gameStatsData?.uniquePlayer || 0);
  const averageRating = gameStatsData?.averageRating?.toFixed(1) || 0;
  const highestScore = formatNumber?.format(gameStatsData?.highestScore || 0);
  const totalRevenue = currencyFormate?.format(
    gameStatsData?.totalRevenue || 0,
  );
  const netRevenue = currencyFormate?.format(gameStatsData?.netRevenue || 0);

  if (gameStatics) {
    <LoaderComponent />;
  }
  return (
    <div>
      <Title level={5} className="mt-0 mb-24">
        Statistics
      </Title>
      <div className="statastics-wrap">
        <div className="statastics-card">
          <UserIcon />
          <Title level={2}> {uniquePlayer}</Title>
          <p> Unique Player</p>
        </div>
        <div className="statastics-card">
          <StarIcon />
          <Title level={2}> {averageRating}</Title>
          <p>Average Rating</p>
        </div>
        <div className="statastics-card">
          <ChartIcon />
          <Title level={2}>{highestScore}</Title>
          <p>Highest Score </p>
        </div>
        <div className="statastics-card">
          <RevenueIcon />
          <Title level={2}> {totalRevenue}</Title>
          <p>Total Revenue</p>
        </div>
        <div className="statastics-card">
          <WithdrawalsIcon />
          <Title level={2}> {netRevenue}</Title>
          <p>Net Revenue</p>
        </div>
      </div>
    </div>
  );
}

export default Statistics;
